const ServiceItem = ({ guarantee, price, time, title, setShowModal }) => {
    return (
        <>
            <div class="price-table__row is-active js-accordion">
                <div class="price-table__column">
                    <div class="price-table__head js-accordion-head">
                        <div class="price-table__title h5">{title}</div>
                        {/* <div class="price-table__arrow">
                            <svg class="icon icon-down" viewBox="0 0 15 15">
                                <use xlinkHref="./icons/sprite.svg#down"></use>
                            </svg>
                        </div> */}
                    </div>
                </div>
                <div class="price-table__column">
                    <div class="price-table__option">
                        <div class="price-table__label">Время ремонта</div>
                        <div class="price-table__text">{time}</div>
                    </div>
                </div>
                <div class="price-table__column">
                    <div class="price-table__option">
                        <div class="price-table__label">Гарантия</div>
                        {guarantee}
                    </div>
                </div>
                <div class="price-table__column flex flex_justify flex_vertical">
                    <div class="price-table__option">
                        <div class="price-table__label">Цена</div>
                        <div class="price-table__text color-blue">
                            <strong>{price}</strong>
                        </div>
                    </div>
                    <button
                        class="price-table__btn btn btn_small btn_white price-table__btn price-table__btn_desktop"
                        onClick={() => setShowModal(true)}
                    >
                        <span class="btn__text">Проконсультироваться</span>
                    </button>
                    <div class="price-table__mobile-box">
                        <div class="price-table__info">
                            *включает в себя только работу мастера, без
                            стоимости детали
                        </div>
                        <div
                            class="price-table__btn btn btn_blue"
                            onClick={() => setShowModal(true)}
                        >
                            <span class="btn__text">Узнать стоимость</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ServiceItem;
