import { useStore } from 'effector-react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { $mainState } from '../../../store/Store';

const HeaderMobile = ({ setMobileCategory }) => {
    const data = useStore($mainState);
    const [cities, setCities] = useState(data?.cities);

    const [isActiveContacts, setIsActiveContacts] = useState(false);
    const [isActivePhone, setIsActivePhone] = useState(false);

    useEffect(() => {
        setCities(data?.cities);
    }, [data]);

    return (
        <>
            <div className="header-mobile js-header-mobile">
                <div className="header-mobile__wrap wrap">
                    <div className="header-mobile__row flex flex_vertical flex_justify">
                        <Link
                            className="header-mobile__logo mobile-logo"
                            to="/"
                        >
                            <span
                                className="mobile-logo__img"
                                onClick={() => window.scrollTo(0, 0)}
                            >
                                <img src="./img/logo.png" />
                            </span>
                            <span className="mobile-logo__text">RS</span>
                        </Link>
                        <div className="header-mobile__nav flex flex_vertical flex_right">
                            {/* awdawdwa */}
                            <div className="header-mobile__buttons">
                                <div className="header-mobile__select js-select is-active">
                                    <div
                                        className="header-mobile__select-btn js-select-head"
                                        onClick={() => {
                                            setIsActiveContacts(
                                                isActiveContacts ? false : true
                                            );
                                            setIsActivePhone(false);
                                        }}
                                    >
                                        <div className="header-mobile__select-icon">
                                            <svg
                                                className="icon icon-location"
                                                viewBox="0 0 18 21"
                                            >
                                                <use xlinkHref="./icons/sprite.svg#location"></use>
                                            </svg>
                                        </div>
                                    </div>
                                    {isActiveContacts && (
                                        <div className="header-mobile__select-body">
                                            <div className="header-mobile__info">
                                                {(cities || []).map((item) => (
                                                    <div
                                                        className="header-mobile__adress"
                                                        key={item?.id}
                                                        onClick={() =>
                                                            data?.mapPosition.scrollIntoView(
                                                                {
                                                                    block: 'center',
                                                                    behavior:
                                                                        'smooth',
                                                                }
                                                            )
                                                        }
                                                    >
                                                        <div className="header-mobile__adress-icon">
                                                            <svg
                                                                className="icon icon-location"
                                                                viewBox="0 0 18 21"
                                                            >
                                                                <use xlinkHref="./icons/sprite.svg#location"></use>
                                                            </svg>
                                                        </div>
                                                        <div className="header-mobile__adress-text">
                                                            {item?.address}
                                                        </div>
                                                        <div className="header-mobile__adress-time">
                                                            {item?.working_days}
                                                            <br />
                                                            {
                                                                item?.working_hours
                                                            }
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="header-mobile__select js-select is-active">
                                    <div
                                        className="header-mobile__select-btn js-select-head"
                                        onClick={() => {
                                            setIsActiveContacts(false);
                                            setIsActivePhone(
                                                isActivePhone ? false : true
                                            );
                                        }}
                                    >
                                        <div className="header-mobile__select-icon">
                                            <svg
                                                className="icon icon-phone"
                                                viewBox="0 0 18 19"
                                            >
                                                <use xlinkHref="./icons/sprite.svg#phone"></use>
                                            </svg>
                                        </div>
                                    </div>
                                    {isActivePhone && (
                                        <div className="header-mobile__select-body">
                                            <div className="header-mobile__contact">
                                                {(cities || []).map((item) => (
                                                    <div className="header-mobile__contact-item">
                                                        <a
                                                            className="header-mobile__phone"
                                                            href="tel:89027508787"
                                                        >
                                                            <span className="header-mobile__phone-icon">
                                                                <svg
                                                                    className="icon icon-phone"
                                                                    viewBox="0 0 18 19"
                                                                >
                                                                    <use xlinkHref="./icons/sprite.svg#phone"></use>
                                                                </svg>
                                                            </span>
                                                            <span className="header-mobile__phone-number">
                                                                {item?.phone}
                                                            </span>
                                                        </a>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div
                                className="header-mobile__burger js-mobile-show"
                                onClick={() => setMobileCategory(true)}
                            >
                                <div className="header-mobile__burger-line"></div>
                                <div className="header-mobile__burger-line"></div>
                                <div className="header-mobile__burger-line"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HeaderMobile;
