import { useEffect, useState } from 'react';
import GalleryItem from './GalleryItem';
import Slider from './Slider';
import VideoModal from './VideoModal';

const Gallery = ({ actualCity }) => {
    const [showModal, setShowModal] = useState(false);
    const [imageArr, setImageArr] = useState([]);
    const [videoModal, setShowVideoModal] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [chooseIndex, setChooseIndex] = useState(0);
    const urls = (imageArr || []).map((item) => item?.source);

    useEffect(() => {
        setImageArr(
            (actualCity?.content || []).filter((el) => el?.type === 'PHOTO')
        );
    }, [actualCity]);

    const videoModalHandler = (url) => {
        setShowVideoModal(true);
        setVideoUrl(url);
    };

    const imageModalHandler = (index) => {
        setShowModal(true);
        setChooseIndex(index);
    };

    return (
        <>
            {showModal && (
                <Slider
                    urls={urls}
                    setShowModal={setShowModal}
                    chooseIndex={chooseIndex}
                />
            )}
            {videoModal && (
                <VideoModal
                    videoUrl={videoUrl}
                    setShowVideoModal={setShowVideoModal}
                />
            )}
            <div class="contact__gallery flex">
                {(actualCity?.content || []).map((item, i) => (
                    <GalleryItem
                        key={item?.id}
                        desription={item?.description}
                        source={item?.source}
                        setShowModal={setShowModal}
                        setShowVideoModal={setShowVideoModal}
                        type={item?.type}
                        videoModalHandler={videoModalHandler}
                        sourceService={item?.source_service}
                        setChooseIndex={setChooseIndex}
                        imageModalHandler={imageModalHandler}
                        index={i}
                    />
                ))}
            </div>
        </>
    );
};

export default Gallery;
