import { createEvent, createStore } from 'effector';

export const $mainState = createStore({
    cities: [],
    category: [],
    promotion: [],
    categoryDetail: {},
    categoryDetailSubcategories: [],
    services: [],
    employees: [],
    mapPosition: 0,
    serviceRef: null,
    workflow: [],
    aboutUs: {},
    advantages: [],
    blog: []
});

export const updateCities = createEvent();
export const updateCategory = createEvent();
export const updatePromotion = createEvent();
export const updateCategoryDetail = createEvent();
export const updateCategoryDetailSubcategories = createEvent();
export const updateServices = createEvent();
export const updateEmployees = createEvent();
export const updateMapPosition = createEvent();
export const updateServiceRef = createEvent();
export const updateWorkflow = createEvent();
export const updateAboutUs = createEvent();
export const updateAdvantages = createEvent();
export const updateBlog = createEvent();

const updateCitiesHandler = (state, newData) => {
    state.cities.push(newData);
    return { ...state };
};

const updateCategoryHandler = (state, newData) => {
    state = {
        ...state,
        category: [...newData],
    };
    return { ...state };
};

const updatePromotionHandler = (state, newData) => {
    state = {
        ...state,
        promotion: [...newData],
    };
    return { ...state };
};

const updateCategoryDetailHandler = (state, newData) => {
    state.categoryDetail = {
        ...newData,
    };
    return { ...state };
};

const updateCategoryDetailSubcategoriesHandler = (state, newData) => {
    state.categoryDetailSubcategories = [
        ...newData,
    ];
    return { ...state };
};

const updateServicesHandler = (state, newData) => {
    state = {
        ...state,
        services: [...newData],
    };

    return { ...state };
};

const updateEmployeesHandler = (state, newData) => {
    state = {
        ...state,
        employees: [...newData],
    };

    return { ...state };
};

const updateMapPositionHandler = (state, newData) => {
    state = {
        ...state,
        mapPosition: newData,
    };
    return { ...state };
};

const updateServiceRefHandler = (state, newData) => {
    state = {
        ...state,
        serviceRef: newData,
    };
    return { ...state };
};

const updateWorkflowHandler = (state, newData) => {
    state = {
        ...state,
        workflow: [...newData],
    };

    return { ...state };
};

const updateAboutUsHandler = (state, newData) => {
    state = {
        ...state,
        aboutUs: newData,
    };
    return { ...state };
};

const updateAdvantagesHandler = (state, newData) => {
    state = {
        ...state,
        advantages: [...newData],
    };
    return { ...state };
};

const updateBlogHandler = (state, newData) => {
    state = {
        ...state,
        blog: [...newData],
    };
    return { ...state };
};

$mainState.on(updateCities, updateCitiesHandler);
$mainState.on(updateCategory, updateCategoryHandler);
$mainState.on(updatePromotion, updatePromotionHandler);
$mainState.on(updateCategoryDetail, updateCategoryDetailHandler);
$mainState.on(updateCategoryDetailSubcategories, updateCategoryDetailSubcategoriesHandler);
$mainState.on(updateServices, updateServicesHandler);
$mainState.on(updateEmployees, updateEmployeesHandler);
$mainState.on(updateMapPosition, updateMapPositionHandler);
$mainState.on(updateServiceRef, updateServiceRefHandler);
$mainState.on(updateWorkflow, updateWorkflowHandler);
$mainState.on(updateAboutUs, updateAboutUsHandler);
$mainState.on(updateAdvantages, updateAdvantagesHandler);
$mainState.on(updateBlog, updateBlogHandler);
