import RepaiItem from './RepairItem';
import { RepairBlockWrapper } from './RepairBlock.styled';
import { useStore } from 'effector-react';
import { $mainState } from '../../../store/Store';
import { useEffect } from 'react';
import { useState } from 'react';

const RepairBlock = () => {
    const data = useStore($mainState);
    const [category, setCategory] = useState(data?.category);

    useEffect(() => {
        setCategory(data?.category);
    }, [data]);

    return (
        <>
            <RepairBlockWrapper>
                <h2>Какую технику будем ремонитровать?</h2>
                <div className="models__nav flex flex_center">
                    {(category || []).map((item) => (
                        <RepaiItem
                            key={item?.id}
                            image={item?.image}
                            subcategory={item?.subcategory}
                            title={item?.title}
                            categoryId={item?.id}
                            categorySlug={item?.slug}
                        />
                    ))}
                </div>
            </RepairBlockWrapper>
        </>
    );
};

export default RepairBlock;
