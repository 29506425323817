import * as Styled from './BlogItem.styled';

const BlogItem = ({ title, text, link, image }) => {
    return (
        <Styled.MainWrapper>
            <Styled.Preview>
                <img src={image} alt="" />{' '}
            </Styled.Preview>
            <Styled.Info>
                <Styled.Title>{title}</Styled.Title>
                <Styled.Description>{text}</Styled.Description>
                <Styled.Link>
                    <a href={link}>Подробнее</a>
                </Styled.Link>
            </Styled.Info>
        </Styled.MainWrapper>
    );
};

export default BlogItem;
