import { useRef } from 'react';
import { useEffect, useState } from 'react';
import {
    YMaps,
    Map,
    Placemark,
    ZoomControl,
    FullscreenControl,
} from 'react-yandex-maps';
import { updateMapPosition } from '../../../store/Store';
import { StyledMapWrapper } from './YaMap.styled';

const YaMap = ({ actualCity }) => {
    const [coords, setCoords] = useState({
        latitude: 55.75,
        longitude: 37.57,
    });
    const mapRef = useRef();

    useEffect(() => {
        setCoords({
            latitude: actualCity?.latitude,
            longitude: actualCity?.longitude,
        });
    }, [actualCity]);

    useEffect(() => {
        updateMapPosition(mapRef?.current);
    }, [mapRef?.current?.getBoundingClientRect().top]);

    return (
        <>
            <div class="contact__maps" ref={mapRef}>
                <YMaps>
                    <StyledMapWrapper>
                        <Map
                            width="100vw"
                            height="493px"
                            defaultState={{
                                center: [coords?.latitude, coords?.longitude],
                                zoom: 17,
                            }}
                            state={{
                                center: [coords?.latitude, coords?.longitude],
                                zoom: 17,
                            }}
                            options={{
                                suppressMapOpenBlock: false,
                                // Удаляет интерактивность POI элементов
                                yandexMapDisablePoiInteractivity: true,
                            }}
                        >
                            <Placemark
                                geometry={[coords?.latitude, coords?.longitude]}
                            />
                            <ZoomControl />
                            <FullscreenControl />
                        </Map>
                    </StyledMapWrapper>
                </YMaps>
            </div>
        </>
    );
};

export default YaMap;
