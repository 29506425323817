import { useStore } from 'effector-react';
import { useState, useEffect } from 'react';
import { $mainState } from '../../../store/Store';
import * as Styled from './ReviewsBlock.styled';
import Review from './Review';

const ReviewsBlock = () => {
    const data = useStore($mainState);
    const [reviewsData, setReviewsData] = useState(
        data?.categoryDetail?.reviews
    );

    const [currentReview, setCurrentReview] = useState(0);

    useEffect(() => {
        setReviewsData(data?.categoryDetail?.reviews);
    }, [data?.categoryDetail?.reviews]);

    const nextReviewClickHandler = () => {
        setCurrentReview((prevState) =>
            prevState === reviewsData?.length - 1 ? 0 : +prevState + 1
        );
    };

    const prevReviewClickHandler = () => {
        setCurrentReview((prevState) =>
            prevState === 0 ? reviewsData?.length - 1 : +prevState - 1
        );
    };

    const currentReviewData = reviewsData && reviewsData[currentReview];
    const dataIsNotEmpty = !!reviewsData?.length;

    return (
        dataIsNotEmpty &&
            <Styled.MainWrapper>
                {dataIsNotEmpty && <Styled.Title>Отзывы</Styled.Title>}
                {dataIsNotEmpty && <Styled.InnerWrapper>
                    <Styled.BrowseButton gridArea = "a" justifySelf = "left"
                        type="button"
                        onClick={prevReviewClickHandler}
                    >
                        <svg>
                            <use xlinkHref="./icons/sprite.svg#chevron_left"></use>
                        </svg>
                    </Styled.BrowseButton>
                    <Styled.ReviewsContainer  gridArea = "c">
                        {currentReviewData && (
                            <Review
                                key={currentReviewData?.id}
                                title={currentReviewData?.title}
                                problem={currentReviewData?.problem}
                                solution={currentReviewData?.solution}
                                image={currentReviewData?.image}
                                amount={currentReviewData?.amount}
                                timeSpend={currentReviewData?.time_spend}
                                description={currentReviewData?.description}
                            />
                        )}
                    </Styled.ReviewsContainer>
                    <Styled.BrowseButton  gridArea = "b" justifySelf = "right"
                        type="button"
                        onClick={nextReviewClickHandler}
                    >
                        <svg>
                            <use xlinkHref="./icons/sprite.svg#chevron_right"></use>
                        </svg>
                    </Styled.BrowseButton>
                </Styled.InnerWrapper>}
            </Styled.MainWrapper>
    );
};

export default ReviewsBlock;
