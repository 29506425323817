import styled from 'styled-components';

export const RepairBlockWrapper = styled.div`
    color: black;
    text-align: center;
    padding: 94rem 0;
    background: #f8f9fc;
    margin: 0 20px;

    @media screen and (max-width: 580px) {
        background: none;
    }

    h2 {
        font-size: 42rem;
    }
`;
