import styled from 'styled-components';

export const SubcategoryWrapper = styled.div`
    border: 1px solid #5a06ff;
    border-radius: 20px;
    padding: 20px;
    margin: 0 10px 20px 10px;

    h3 {
        font-size: 20rem;
    }

    @media (max-width: 470px) {
        width: 100%;
        margin: 0 0 20px 0;
        display: flex;
        justify-content: center;
    }
`;