import { useStore } from 'effector-react';
import { useEffect, useState } from 'react';
import { $mainState } from '../../../store/Store';
import PromotionItem from './PromotionItem';

const Promotion = () => {
    const data = useStore($mainState);
    const [promotionData, setPromotionData] = useState(data?.promotion);

    useEffect(() => {
        setPromotionData(data?.promotion);
    }, [data?.promotion]);

    return (
        <>
            <div class="repairs">
                <div class="repairs__wrap wrap">
                    <div class="repairs__title h2">
                        Ремонтируем более 90% поломок
                    </div>
                    <div class="repairs__list flex js-wrapSlider">
                        {(promotionData || []).map((item) => (
                            <PromotionItem
                                key={item?.id}
                                description={item?.description}
                                image={item?.image}
                                title={item?.title}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Promotion;
