import SubcategoryItem from './SubcategoryItem';
import { Link } from 'react-router-dom';

import { useStore } from 'effector-react';
import { $mainState } from '../../../store/Store';
import { useEffect } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const CategoryItem = ({ title, subcategory, id, categorySlug }) => {
    const data = useStore($mainState);
    const [ref, setRef] = useState();

    const location = useLocation();

    const needScroll =
        location.pathname === '/' ||
        location.pathname === '/contacts' ||
        location.pathname === '/blog';

    useEffect(() => {
        setRef(data.serviceRef);

        if (!needScroll && window.pageYOffset < 100) {
            window.scrollTo({
                top: 900,
                behavior: 'smooth',
            });
        }
    });

    return (
        <>
            <li class="header__nav-item" style={{ fontSize: '16px' }}>
                <Link class="header__nav-link" to={`/${categorySlug}`}>
                    {title}
                </Link>
                {!!subcategory.length && (
                    <ul class="header__sub" style={{ zIndex: '999' }}>
                        {(subcategory || []).map((item) => (
                            <SubcategoryItem
                                key={item?.id}
                                title={item?.title}
                                id={id}
                                subcategoryId={item?.id}
                                categorySlug={categorySlug}
                                subcategorySlug={item?.slug}
                                handleServiceScroll={() => {
                                    window.scrollTo({
                                        top: 900,
                                        behavior: 'smooth',
                                    });
                                }}
                            />
                        ))}
                    </ul>
                )}
            </li>
        </>
    );
};

export default CategoryItem;
