import styled from 'styled-components';

export const MainWrapper = styled.div`
    width: 100%;
    margin: 26rem;
    display: block;
    padding: 18rem 14rem 39rem;
    background: #fff;
    -webkit-box-shadow: 0 40px 84px rgba(0, 129, 170, 0.05);
    box-shadow: 0 40px 84px rgba(0, 129, 170, 0.05);
    border-radius: 30rem;

    @media only screen and (max-width: 1120px) {
        margin: 10px;
    }
    @media only screen and (max-width: 580px) {
        -webkit-box-shadow: none;
        box-shadow: none;
        height: 100%;
        padding: 12rem 10rem 30rem;
        border-radius: 20rem;
    }
`;

export const Preview = styled.div`
    display: block;
    position: relative;
    padding-top: 35%;
    border-radius: 20rem;
    overflow: hidden;

    @media only screen and (max-width: 580px) {
        padding-top: 66%;
    }

    img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
    }
`;

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25rem;
`;

export const Title = styled.div`
    display: block;
    font-weight: 700;
    font-size: 24rem;
    line-height: 110%;

    @media only screen and (max-width: 580px) {
        font-size: 16rem;
    }

    @media only screen and (max-width: 980px) {
        font-size: 20rem;
    }
`;

export const Description = styled.div`
    display: block;
    text-align: center;
    margin: 12rem auto 0;
    font-size: 18rem;
    line-height: 110%;

    @media only screen and (max-width: 980px) {
        font-size: 16rem;
    }

    @media only screen and (max-width: 580px) {
        font-size: 14rem;
        margin-top: 10rem;
    }
`;

export const Link = styled.div`

    @media only screen and (max-width: 580px) {
        width: 100%;
    }

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 355rem;
        height: 50rem;
        margin-top: 25rem;
        padding: 0 20rem;
        border: 0;
        border-radius: 15rem;
        background: #5a06ff;
        color: #fff;
        font-size: 17rem;
        cursor: pointer;

        @media only screen and (max-width: 1120px) {
            height: 50rem;
            font-size: 14rem;
            font-weight: 700;
        }

        @media only screen and (max-width: 580px) {
            font-size: 13rem;
            width: 100%;
        }
    }
`;
