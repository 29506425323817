import styled from 'styled-components';

export const MainWrapper = styled.div`
    padding: 113rem 0 0 0;
    background: #f8f9fc;
    overflow: hidden;

    @media only screen and (max-width: 580px) {
        padding: 60rem 0 79rem;
        background: #f0f1f4;
    }
`;

export const InnerWrapper = styled.div`
    padding: 0 100px;
    max-width: 1640rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: 1120px) {
        padding: 0 20px;
    }

    @media only screen and (max-width: 580px) {
    }
`;

export const Title = styled.div`
    margin-bottom: 40px;
    text-align: center;
    font-weight: 700;
    font-size: 54rem;
    line-height: 100%;

    @media only screen and (max-width: 580px) {
        font-size: 30rem;
    }

    @media only screen and (max-width: 1440px) {
        font-size: 50rem;
    }
    @media only screen and (max-width: 1120px) {
        font-size: 42rem;
    }
    @media only screen and (max-width: 768px) {
        font-size: 38rem;
    }
    @media only screen and (max-width: 580px) {
        font-size: 24rem;
    }
`;

export const BoldText = styled.div`
    color: #5a06ff;
    font-weight: 700;
    font-size: 24rem;
    text-align: center;

    @media only screen and (max-width: 980px) {
        font-size: 20rem;
    }

    @media only screen and (max-width: 580px) {
        font-size: 16rem;
    }
`;

export const TextContainer = styled.div`
    max-width: 900px;
    margin-top: 40px;
    font-size: 18rem;
    font-style: italic;
    border: 2px solid #c5cad9;
    border-radius: 5px;
    position: relative;
    padding: 25px;

    @media only screen and (max-width: 980px) {
        font-size: 16rem;
    }

    @media only screen and (max-width: 580px) {
        font-size: 14rem;
    }
`;

export const Corner = styled.div`
    position: absolute;
    top: -2px;
    left: -2px;
    background: #f8f9fc;
    width: 30px;
    height: 30px;

    @media only screen and (max-width: 580px) {
        background: #f0f1f4;
    }
`
