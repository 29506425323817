import { useStore } from 'effector-react';
import { useState, useEffect } from 'react';
import { $mainState } from '../../../store/Store';
import * as Styled from './AboutUs.styled';

const AboutUs = () => {
    const data = useStore($mainState);
    const [aboutUsData, setAboutUsData] = useState(data?.aboutUs);

    useEffect(() => {
        setAboutUsData(data?.aboutUs);
    }, [data?.aboutUs]);

    return (
        <>
            {!!aboutUsData && (
                <Styled.MainWrapper>
                    <Styled.InnerWrapper>
                        <Styled.Title>{aboutUsData?.title}</Styled.Title>
                        <Styled.BoldText>
                            {aboutUsData?.title_small}
                        </Styled.BoldText>
                        <Styled.TextContainer>
                            <Styled.Corner />
                            {aboutUsData?.description}
                        </Styled.TextContainer>
                    </Styled.InnerWrapper>
                </Styled.MainWrapper>
            )}
        </>
    );
};

export default AboutUs;
