import styled from 'styled-components';

export const RepairItemWrapper = styled.div`
    border: 1px solid #5a06ff;
    border-radius: 20px;
    display: flex;
    padding: 20px;
    margin-right: 20px;
    margin-bottom: 20px;

    &:first-child {
        margin-left: 20px;

        @media (max-width: 696px) {
            margin-left: 0;
        }
    }

    &:last-child {
        margin-right: 0;
    }

    @media (max-width: 696px) {
        width: 100%;
        margin-right: 0;
        display: block;
    }
`;

export const RepaitItemImg = styled.div`
    width: 100px;
    height: 110px;
    margin-right: 20px;

    img {
        width: 100%;
        height: 100%;

        @media (max-width: 696px) {
            width: 100px;
            height: 100px;
        }
    }

    @media (max-width: 696px) {
        display: flex;
        justify-content: center;
        width: 100%;
    }
`;

export const RepairInfo = styled.div`
    text-align: left;

    h3 {
        font-size: 20rem;
        margin-bottom: 20px;
    }

    @media (max-width: 696px) {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
`;

export const RepairItemSubcategory = styled.div`
    display: flex;
    margin-bottom: 25px;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    a {
        color: #5a06ff;
        margin-right: 10px;
        margin-bottom: 10px;

        &:last-child {
            margin-right: 0;
            margin-bottom: 0;
        }
    }
`;

export const RepairItembuttonWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

export const RepairItemButton = styled.div`
    border: 1px solid #5a06ff;
    padding: 5px 10px;
    border-radius: 10px;
`;
