import { useStore } from 'effector-react';
import { useState, useEffect } from 'react';
import { $mainState } from '../../../store/Store';
import * as Styled from './SubcategoriesBlock.styled';
import Subcategory from './Subcategory';

const SubcategoriesBlock = () => {
    const data = useStore($mainState);
    const [categoryDetailData, setCategoryDetailData] = useState(
        data?.categoryDetail
    );
    const [subcategoriesData, setSubcategoriesData] = useState(
        data?.categoryDetailSubcategories
    );
    const [ref, setRef] = useState();

    useEffect(() => {
        setCategoryDetailData(data?.categoryDetail);
    }, [data?.categoryDetail]);

    useEffect(() => {
        setSubcategoriesData(data?.categoryDetailSubcategories);
    }, [data?.categoryDetailSubcategories]);

    useEffect(() => {
        setRef(data.serviceRef);

        data?.serviceRef?.scrollIntoView({
            block: 'center',
            behavior: 'smooth',
        });
    }, [data.serviceRef]);

    return (
        !!subcategoriesData.length && (
            <Styled.MainWrapper>
                {(subcategoriesData || []).map((item) => (
                    <Subcategory
                        key={item?.id}
                        title={item?.title}
                        categorySlug={categoryDetailData?.slug}
                        subcategorySlug={item?.slug}
                        handleServiceScroll={() =>
                            ref?.current?.scrollIntoView({
                                block: 'center',
                                behavior: 'smooth',
                            })
                        }
                    />
                ))}
            </Styled.MainWrapper>
        )
    );
};

export default SubcategoriesBlock;
