import styled from 'styled-components';

export const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100vh;
    z-index: 998;
`;

export const MainClose = styled.img`
    position: absolute;
    top: 25px;
    right: 25px;
    width: 25px;
    height: 25px;
    z-index: 999;
    cursor: pointer;

    @media (max-width: 768px) {
        top: 15px;
        right: 15px;
        width: 20px;
        height: 20px;
    }
`;
