import { Link } from 'react-router-dom';

const SubcategoryItem = ({
    title,
    id,
    subcategoryId,
    categorySlug,
    subcategorySlug,
    handleServiceScroll,
}) => {
    return (
        <>
            <li class="header__sub-item">
                <Link
                    class="header__sub-link"
                    to={`/${categorySlug}/${subcategorySlug}`}
                    onClick={() => handleServiceScroll()}
                >
                    {title}
                </Link>
            </li>
        </>
    );
};

export default SubcategoryItem;
