import * as Styled from './SuccessModal.styled';
import useScrollLock from './useScrollLock';

const SuccessModal = ({ setSuccessModal }) => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    useScrollLock();

    return (
        <>
            <Styled.SuccessModalWrapper scrollTop={scrollTop}>
                <div
                    class="modals modals_thank"
                    style={{ top: 'calc(50% - 164px)' }}
                >
                    <Styled.SuccessClose
                        src="./img/close.png"
                        alt="close"
                        onClick={() => setSuccessModal(false)}
                    />
                    <div class="modals__thank">
                        <div class="modals__thank-icon">
                            <img src="./img/ok.svg" />
                        </div>
                        <div class="modals__thank-title">Спасибо!</div>
                        <div class="modals__thank-text">
                            Данные успешно отправлены
                        </div>
                    </div>
                </div>
            </Styled.SuccessModalWrapper>
        </>
    );
};

export default SuccessModal;
