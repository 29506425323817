import axios from 'axios';
import { APIWrapper } from '../../help/APIWrapper';

export const getCitiesAPI = axios.create({
    baseURL: `${APIWrapper('/api/city/')}`,
});

export const getCitiesContactsAPI = axios.create({
    baseURL: `${APIWrapper('/city/1/contacts/')}`,
});

export const getCategoryAPI = axios.create({
    baseURL: `${APIWrapper('/api/category/')}`,
});

export const getPromotionAPI = axios.create({
    baseURL: `${APIWrapper('/api/promotion/')}`,
});

export const getCaterySlugAPI = axios.create({
    baseURL: `${APIWrapper('/api/category/')}`,
});

export const getServicesAPI = axios.create({
    baseURL: `${APIWrapper('/api/service/')}`,
});

export const getEmployeesAPI = axios.create({
    baseURL: `${APIWrapper('/api/employee/')}`,
});

export const getWorkflowAPI = axios.create({
    baseURL: `${APIWrapper('/api/workflow/')}`,
})

export const getAboutUsAPI = axios.create({
    baseURL: `${APIWrapper('/api/about/')}`,
})

export const getAdvantagesAPI = axios.create({
    baseURL: `${APIWrapper('/api/advantage/')}`,
})

export const getBlogAPI = axios.create({
    baseURL: `${APIWrapper('/api/blog/')}`,
})

export const sendFeedbackAPI = axios.create({
    baseURL: `${APIWrapper('/api/feedback/')}`,
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFTOKEN',
    withCredentials: true
});
