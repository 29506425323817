const InfoGarranty = () => {
    return (
        <>
            <div class="main__item">
                <div class="main__item-icon">
                    <img src="./img/main__icon1.svg" />
                </div>
                <div class="main__item-title h5">
                    Срочный ремонт от 20 минут
                </div>
                <div class="main__item-text color-gray">
                    свои специалисты - никуда не носим
                </div>
            </div>
            <div class="main__item">
                <div class="main__item-icon">
                    <img src="./img/main__icon2.svg" />
                </div>
                <div class="main__item-title h5">Бесплатный курьер*</div>
                <div class="main__item-text color-gray">
                    Не нужно тратить время
                </div>
            </div>
            <div class="main__item">
                <div class="main__item-icon">
                    <img src="./img/main__icon3.svg" />
                </div>
                <div class="main__item-title h5">Юридическая гарантия</div>
                <div class="main__item-text color-gray">
                    К любому ремонту выдаем документ
                </div>
            </div>
            <div class="main__item">
                <div class="main__item-icon">
                    <img src="./img/main__icon4.svg" />
                </div>
                <div class="main__item-title h5">
                    Хорошие <br />
                    отзывы
                </div>
                <div class="main__item-text color-gray">
                    более 200 отзывов, средняя оценка 4,7
                </div>
            </div>
        </>
    );
};

export default InfoGarranty;
