import { useStore } from 'effector-react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { $mainState } from '../../../store/Store';

const CategoryMobile = ({ setMobileCategory }) => {
    const data = useStore($mainState);
    const [cities, setCities] = useState(data?.cities);
    const [category, setCategory] = useState(data?.category);

    useEffect(() => {
        setCities(data?.cities);
        setCategory(data?.category);
    }, [data]);

    return (
        <>
            <div class="mobile js-mobile is-active">
                <div class="mobile__wrap wrap">
                    <div class="mobile__top flex flex_vertical flex_justify">
                        <Link class="mobile__logo mobile-logo" to="/">
                            <span class="mobile-logo__img">
                                <img src="./img/logo.png" />
                            </span>
                            <span class="mobile-logo__text">RS</span>
                        </Link>
                        <div
                            class="mobile__close js-mobile-close"
                            onClick={() => setMobileCategory(false)}
                        >
                            <svg class="icon icon-close" viewBox="0 0 34 35">
                                <use xlinkHref="./icons/sprite.svg#close"></use>
                            </svg>
                        </div>
                    </div>
                    <div class="mobile__nav mobile-nav">
                        <div class="mobile-nav__item">
                            <a class="mobile-nav__link" href="#">
                                Главная
                            </a>
                        </div>
                        <div className="mobile-nav__item">
                            <Link className="mobile-nav__link" to="/blog">
                                Блог
                            </Link>
                        </div>
                        {(category || []).map((item) => (
                            <div
                                class="mobile-nav__item js-accordion is-active"
                                key={item?.id}
                            >
                                <div class="mobile-nav__head">
                                    <Link
                                        class="mobile-nav__link"
                                        to={`/${item?.slug}`}
                                    >
                                        {item?.title}
                                    </Link>
                                </div>
                                <div class="mobile-nav__body">
                                    <ul class="mobile-nav__list">
                                        {(item?.subcategory || []).map(
                                            (subcategory) => (
                                                <li
                                                    class="mobile-nav__list-item"
                                                    key={subcategory?.id}
                                                >
                                                    <Link
                                                        class="mobile-nav__list-link"
                                                        to={`/${item?.slug}/${subcategory?.slug}`}
                                                    >
                                                        <span class="mobile-nav__list-text">
                                                            {subcategory?.title}
                                                        </span>
                                                        <span class="mobile-nav__list-arrow">
                                                            <svg
                                                                class="icon icon-arrowRight"
                                                                viewBox="0 0 12 12"
                                                            >
                                                                <use xlinkHref="./icons/sprite.svg#arrowRight"></use>
                                                            </svg>
                                                        </span>
                                                    </Link>
                                                </li>
                                            )
                                        )}
                                    </ul>
                                </div>
                            </div>
                        ))}
                        <div class="mobile-nav__item">
                            <Link class="mobile-nav__link" to="/contacts">
                                Контакты
                            </Link>
                        </div>
                    </div>
                    <div class="mobile__contact">
                        {(cities || []).map((item) => (
                            <div class="mobile__contact-item" key={item?.id}>
                                <a class="mobile__phone" href="tel:89027548444">
                                    <span class="mobile__phone-icon">
                                        <svg
                                            class="icon icon-phone"
                                            viewBox="0 0 18 19"
                                        >
                                            <use xlinkHref="./icons/sprite.svg#phone"></use>
                                        </svg>
                                    </span>
                                    <span class="mobile__phone-text">
                                        {item?.phone}
                                    </span>
                                </a>
                                <div class="mobile__adress">
                                    <div class="mobile__adress-icon">
                                        <svg
                                            class="icon icon-location"
                                            viewBox="0 0 18 21"
                                        >
                                            <use xlinkHref="/app/icons/sprite.svg#location"></use>
                                        </svg>
                                    </div>
                                    <div class="mobile__adress-info">
                                        <div class="mobile__adress-title h4">
                                            <strong>
                                                {`г. ${item?.name} ${item?.address}`}
                                            </strong>
                                        </div>
                                        <div class="mobile__adress-text">
                                            {`${item?.working_days} с ${item?.working_hours}`}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div class="mobile__social social">
                        <a
                            class="social__link social__link_whatsapp"
                            href="https://wa.me/+79027508787"
                        >
                            <svg class="icon icon-whatsapp" viewBox="0 0 26 26">
                                <use xlinkHref="./icons/sprite.svg#whatsapp"></use>
                            </svg>
                        </a>
                        <a
                            class="social__link social__link_vk"
                            href="https://vk.com/repair.srevice71"
                        >
                            <svg class="icon icon-vk" viewBox="0 0 25 14">
                                <use xlinkHref="./icons/sprite.svg#vk"></use>
                            </svg>
                        </a>
                        <a
                            class="social__link"
                            href="https://t.me/repairservice71"
                        >
                            <img
                                class="icon icon-instagram"
                                src="./img/telega.png"
                                alt="telega"
                            />
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CategoryMobile;
