import axios from 'axios';
import { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {
    updateCategoryDetail,
    updateCategoryDetailSubcategories,
    updatePromotion,
    updateServices,
    updateEmployees,
    updateWorkflow,
    updateAboutUs,
    updateAdvantages
} from '../../store/Store';
import { APIWrapper } from '../help/APIWrapper';
import MainModal from '../help/MainModal';
import SuccessModal from '../help/SuccessModal';
import { getCaterySlugAPI, getPromotionAPI, getEmployeesAPI, getWorkflowAPI, getAboutUsAPI, getAdvantagesAPI } from './api/MainAPI';
import MainContacts from './contacts/MainContacts';
import Info from './info/Info';
import Promotion from './promotion/Promotion';
import Services from './services/Services';
import Employees from '../main/employees/Employees';
import SubcategoriesBlock from './subcategories/SubcategoriesBlock';
import ReviewsBlock from '../main/reviews/ReviewsBlock';
import RepairTermsAndCost from './repairTermsAndCost/RepairTermsAndCost';
import Consult from './consult/Consult';
import WorkflowBlock from './workflow/WorkflowBlock';

const CategoryPage = ({ setMobileCategory }) => {
    const { title } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [showModal, setShowModal] = useState(false);
    const [showSuccessModal, setSuccessModal] = useState(false);
    const location = useLocation();

    const subcategoriesLoadHandler = useCallback(async () => {
        try {
            const result = await axios.get(APIWrapper(`/api/category/${title}/subcategory`));
            updateCategoryDetailSubcategories(result?.data);
        }
        catch(error) {
            console.log(error); 
        }
    }, [title]);

    useEffect(() => {
        setMobileCategory(false);
    }, [location]);

    useEffect(() => {
        getCaterySlugAPI.get(title).then((res) => {
            updateCategoryDetail(res?.data);
        });

        subcategoriesLoadHandler();

        axios.get(APIWrapper(`/api/category/${title}/service`)).then((res) => {
            updateServices(res?.data);
        });

        getPromotionAPI.get().then((res) => {
            updatePromotion(res?.data);
        });

        getEmployeesAPI.get().then((res) => {
            updateEmployees(res?.data)
        });

        getWorkflowAPI.get().then((res) => {
            updateWorkflow(res?.data)
        });

        getAboutUsAPI.get().then((res) => {
            updateAboutUs(res?.data)
        });

        getAdvantagesAPI.get().then((res) => {
            updateAdvantages(res?.data);
        });
    });

    return (
        <>
            <div class="main">
                <Info isCategory setShowModal={setShowModal} />
                <Services setShowModal={setShowModal} />
                <Consult setShowModal={setShowModal}/>
                <SubcategoriesBlock />
                <ReviewsBlock />
                <Employees />
                <RepairTermsAndCost />
                <WorkflowBlock />
                <Promotion />
                <MainContacts />
            </div>
            {showModal && (
                <MainModal
                    setShowModal={setShowModal}
                    setSuccessModal={setSuccessModal}
                />
            )}
            {showSuccessModal && (
                <SuccessModal setSuccessModal={setSuccessModal} />
            )}
        </>
    );
};

export default CategoryPage;
