import styled from 'styled-components';

export const SuccessModalWrapper = styled.div`
    position: absolute;
    top: ${(props) => props.scrollTop}px;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100vh;
    z-index: 999;
`;

export const SuccessClose = styled.img`
    position: absolute;
    top: 25px;
    right: 25px;
    width: 25px;
    height: 25px;
    z-index: 999;
    cursor: pointer;
`;
