import { useStore } from 'effector-react';
import { useEffect, useState } from 'react';
import { $mainState } from '../../../store/Store';
import Phones from './Phones';

const Contacts = ({
    address,
    workingDays,
    workingHours,
    workingTimes,
    name,
    mapRef,
    phone,
}) => {
    const data = useStore($mainState);

    return (
        <>
            <div
                className="header__adress"
                onClick={() =>
                    data?.mapPosition.scrollIntoView({
                        block: 'center',
                        behavior: 'smooth',
                    })
                }
                style={{ cursor: 'pointer' }}
            >
                <div className="header__adress-icon">
                    <svg className="icon icon-location" viewBox="0 0 18 21">
                        <use xlinkHref="./icons/sprite.svg#location"></use>
                    </svg>
                </div>
                <div className="header__adress-text">
                    <strong>{`${name} ${address}`}</strong>
                    {workingTimes?.map((item) => (
                        <p>
                            {item.day} {item.time}
                        </p>
                    ))}
                    <div class="header__contact">
                        <div class="header__contact-icon">
                            <svg class="icon icon-phone" viewBox="0 0 18 19">
                                <use xlinkHref="./icons/sprite.svg#phone"></use>
                            </svg>
                        </div>
                        <div class="header__contact-list">
                            <Phones phone={phone} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Contacts;
