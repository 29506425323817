import styled from 'styled-components';

export const MainWrapper = styled.div`
    padding: 113rem 0 79rem 0;
    background: #f8f9fc;
    overflow: hidden;

    @media only screen and (max-width: 1120px) {
        padding: 60rem 0 79rem 0;
    }

    @media only screen and (max-width: 580px) {
        padding: 60rem 0 79rem;
    }
`;

export const InnerWrapper = styled.div`
    padding: 0 100px;
    max-width: 1640rem;
    margin: auto;
    position: relative;

    @media only screen and (max-width: 1120px) {
            padding: 0 20px;
    }
`;

export const Title = styled.div`
    text-align: center;
    font-weight: 700;
    font-size: 54rem;
    line-height: 100%;

    @media only screen and (max-width: 580px) {
        font-size: 30rem;
    }

    @media only screen and (max-width: 1440px) {
        font-size: 50rem;
    }
    @media only screen and (max-width: 1120px) {
        font-size: 42rem;
    }
    @media only screen and (max-width: 768px) {
        font-size: 38rem;
    }
    @media only screen and (max-width: 580px) {
        font-size: 24rem;
    }
`;

export const Container = styled.div`
    margin: 0 -26rem;
    padding-top: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: 1120px) {
        margin: 0 -10px;
    }
    @media only screen and (max-width: 580px) {
        padding-top: 0;
        margin: 30rem -20px 0 0;
        width: calc(100% + 20px);
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;
