import React, { useRef } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Category from './main/category/Category';
import Footer from './main/footer/Footer';
import Header from './main/header/Header';
import MainPage from './main/MainPage';
import { useEffect, useState } from 'react';
import {
    getCategoryAPI,
    getCitiesAPI,
    getPromotionAPI,
} from './main/api/MainAPI';
import axios from 'axios';
import { APIWrapper } from './help/APIWrapper';
import { updateCategory, updateCities, updatePromotion } from '../store/Store';
import CategoryPage from './main/CategoryPage';
import SubcategoryPage from './main/SubcategoryPage';
import ContactsPage from './main/ContactsPage';
import BlogPage from './main/BlogPage';
import HeaderMobile from './main/header/HeaderMobile';
import CategoryMobile from './main/category/CategoryMobile';

const App = () => {
    const [mobileCategory, setMobileCategory] = useState(false);

    useEffect(() => {
        getCitiesAPI.get().then((res) => {
            (res?.data || []).map((contactRes, contactIndex) => {
                axios
                    .get(APIWrapper(`/api/city/${contactRes?.id}/contacts/`))
                    .then((finalRes) => {
                        updateCities({
                            ...res?.data[contactIndex],
                            ...finalRes?.data,
                        });
                    });
            });
        });

        getCategoryAPI.get().then((res) => {
            updateCategory(res.data);
        });

        getPromotionAPI.get().then((res) => {
            updatePromotion(res.data);
        });
    }, []);

    return (
        <Router>
            <Header />
            <HeaderMobile setMobileCategory={setMobileCategory} />
            <Category />
            {mobileCategory && (
                <CategoryMobile setMobileCategory={setMobileCategory} />
            )}

            <Switch>
                <Route path="/contacts">
                    <ContactsPage setMobileCategory={setMobileCategory} />
                </Route>
                <Route path="/blog">
                    <BlogPage setMobileCategory={setMobileCategory} />
                </Route>
                <Route path="/:title/:subcategoryTitle">
                    <SubcategoryPage setMobileCategory={setMobileCategory} />
                </Route>
                <Route path="/:title">
                    <CategoryPage setMobileCategory={setMobileCategory} />
                </Route>
                <Route path="/">
                    <MainPage setMobileCategory={setMobileCategory} />
                </Route>
            </Switch>

            <Footer />
        </Router>
    );
};

export default App;
