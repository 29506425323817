const ChooseCity = ({ cities, actualCity, setActualCity }) => {
    const clickHadler = (index) => {
        setActualCity({
            ...cities[index],
            choose: true,
        });
    };

    return (
        <>
            <div class="contact__head flex flex_vertical flex_justify">
                <div class="contact__head-column contact__head-column_left">
                    <div class="contact__title h2">Контакты:</div>
                    <div class="contact__desc">Где нас найти?</div>
                </div>
                <div class="contact__head-column contact__head-column_right">
                    <div class="contact__text h5">
                        Выберите город и узнайте адрес:
                    </div>
                    <div class="contact__nav flex">
                        {(cities || []).map((item, index) => (
                            <a
                                key={item?.id}
                                class={`contact__btn btn btn_border btn_medium ${
                                    item?.id === actualCity?.id
                                        ? 'is-active'
                                        : ''
                                } js-tab-link`}
                                onClick={() => clickHadler(index)}
                            >
                                <span class="btn__text">{item?.name}</span>
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ChooseCity;
