import { Link } from 'react-router-dom';
import {
    RepairItemWrapper,
    RepaitItemImg,
    RepairInfo,
    RepairItemSubcategory,
    RepairItemButton,
    RepairItembuttonWrapper,
} from './RepairItem.styled';

const RepaiItem = ({ image, subcategory, title, categoryId, categorySlug }) => {
    return (
        <>
            <RepairItemWrapper>
                <RepaitItemImg>
                    <img src={image} alt="categoryImage" />
                </RepaitItemImg>
                <RepairInfo>
                    <h3>{title}</h3>
                    <RepairItemSubcategory>
                        {(subcategory || []).map((item) => (
                            <Link to={`/${categorySlug}/${item?.slug}`}>
                                {item?.title}
                            </Link>
                        ))}
                    </RepairItemSubcategory>
                    <RepairItembuttonWrapper>
                        <Link
                            to={`/${categorySlug}`}
                            style={{
                                border: '1px solid #5a06ff',
                                padding: '5px 10px',
                                borderRadius: '10px',
                            }}
                        >
                            Все услуги
                        </Link>
                    </RepairItembuttonWrapper>
                </RepairInfo>
            </RepairItemWrapper>
        </>
    );
};

export default RepaiItem;
