import styled from 'styled-components';

export const SliderWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100vh;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
        width: 1059px;
        height: 609px;
        border-radius: 10px;
        border: 1px solid white;
        border-collapse: collapse;
        position: relative;

        @media (max-width: 1114px) {
            width: calc(1059px / 2);
            height: calc(609px / 2);
        }

        @media (max-width: 578px) {
            width: calc(1059px / 3);
            height: calc(609px / 3);
        }
    }
`;

export const SliderMainImg = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 10px;
`;

export const PrevButton = styled.img`
    position: absolute;
    top: 50%;
    left: -25px;
    width: 50px;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;

    @media (max-width: 1114px) {
        top: 45%;
    }

    @media (max-width: 578px) {
        top: 40%;
    }
`;

export const NextButton = styled.img`
    position: absolute;
    top: 50%;
    right: -25px;
    width: 50px;
    border-radius: 50%;
    transform: rotate(180deg);
    background-color: white;
    cursor: pointer;

    @media (max-width: 1114px) {
        top: 45%;
    }

    @media (max-width: 578px) {
        top: 40%;
    }
`;

export const CloseButton = styled.img`
    position: absolute;
    top: -40px;
    right: 0px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    filter: invert(1);

    @media (max-width: 1114px) {
        top: 15px;
        right: 15px;
    }
`;
