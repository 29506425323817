import styled from 'styled-components';

export const EmployeeWrapper = styled.div`
    width: calc(33.33% - 52rem);
    margin: 26rem;
    display: block;
    padding: 18rem 14rem 39rem;
    background: #fff;
    -webkit-box-shadow: 0 40px 84px rgba(0, 129, 170, 0.05);
    box-shadow: 0 40px 84px rgba(0, 129, 170, 0.05);
    border-radius: 30rem;

    @media only screen and (max-width: 1120px) {
        width: calc(33.33% - 20px);
        margin: 10px;
    }
    @media only screen and (max-width: 768px) {
        width: calc(50% - 20px);
    }
    @media only screen and (max-width: 580px) {
        margin: 0;
        width: 270rem;
        -webkit-box-shadow: none;
        box-shadow: none;
        height: 100%;
        padding: 12rem 10rem 30rem;
        border-radius: 20rem;
    }
`;

export const Preview = styled.div`
    display: block;
    position: relative;
    padding-top: 59.24171%;
    border-radius: 20rem;
    overflow: hidden;

    @media only screen and (max-width: 580px) {
        padding-top: 66%;
    }

    picture {
        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
        }
    }
`;

export const EmployeeInfo = styled.div`
    text-align: center;
    display: block;
    margin-top: 25rem;
`;

export const Name = styled.div`
    display: block;
    font-weight: 700;
    font-size: 24rem;
    line-height: 110%;

    @media only screen and (max-width: 580px) {
        font-size: 16rem;
    }

    @media only screen and (max-width: 980px) {
        font-size: 20rem;
    }
`;

export const Description = styled.div`
    display: block;
    max-width: 350rem;
    margin: 12rem auto 0;
    font-size: 18rem;
    line-height: 110%;

    @media only screen and (max-width: 980px) {
        font-size: 16rem;
    }

    @media only screen and (max-width: 580px) {
        font-size: 14rem;
        margin-top: 10rem;
    }
`;
