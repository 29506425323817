import CategoryItem from './CategoryItem';
import { useStore } from 'effector-react';
import { $mainState } from '../../../store/Store';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const Category = () => {
    const data = useStore($mainState);
    const [category, setCategory] = useState(data?.category);

    useEffect(() => {
        setCategory(data?.category);
    }, [data.category]);

    return (
        <>
            <div
                className="header__bottom"
                style={{ padding: '35rem', fontSize: '16px' }}
            >
                <div
                    className="header__wrap wrap"
                    style={{ paddingLeft: '80px', paddingRight: '80px' }}
                >
                    <ul className="header__nav">
                        <li className="header__nav-item">
                            <Link className="header__nav-link is-active" to="/">
                                Главная
                            </Link>
                        </li>
                        {(category || []).map((item) => (
                            <CategoryItem
                                key={item?.id}
                                title={item?.title}
                                subcategory={item?.subcategory}
                                id={item?.id}
                                categorySlug={item?.slug}
                            />
                        ))}
                        <li className="header__nav-item">
                            <Link className="header__nav-link" to="/contacts">
                                Контакты
                            </Link>
                        </li>
                        <li className="header__nav-item">
                            <Link className="header__nav-link" to="/blog">
                                Блог
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default Category;
