import { useStore } from 'effector-react';
import { useEffect, useState } from 'react';
import { $mainState } from '../../../store/Store';
import * as Styled from './Employees.styled';
import EmployeesItem from './EmployeesItem';

const Employees = () => {
    const data = useStore($mainState);
    const [employeesData, setEmployeesData] = useState(data?.employees);

    useEffect(() => {
        setEmployeesData(data?.employees);
    }, [data?.employees]);

    return (
        !!employeesData?.length &&
            <Styled.EmployeesWrapper>
                <Styled.InnerWrapper>
                    {!!employeesData?.length && <Styled.Title>Наш коллектив</Styled.Title>}
                    <Styled.Container>
                        {(employeesData || []).map((item) => (
                            <EmployeesItem
                                key={item?.id}
                                name={item?.name}
                                surname={item?.surname}
                                description={item?.description}
                                image={item?.image}
                                workExperience={item?.work_experience}
                            />
                        ))}
                    </Styled.Container>
                </Styled.InnerWrapper>
            </Styled.EmployeesWrapper>
    );
};

export default Employees;
