import { useEffect } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Blog from './blog/Blog';
import { updateBlog } from '../../store/Store';
import { getBlogAPI } from './api/MainAPI';

const BlogPage = ({ setMobileCategory }) => {
    const location = useLocation();

    useEffect(() => {
        setMobileCategory(false);
    }, [location]);

    useEffect(() => {
        getBlogAPI.get().then((res) => {
            updateBlog(res?.data);
        });
    });

    return (
        <>
            <Blog type="blog" header="Блог" />
        </>
    );
};

export default BlogPage;
