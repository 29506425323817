import { useEffect } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import MainContacts from './contacts/MainContacts';
import { updateAboutUs } from '../../store/Store';
import { getAboutUsAPI } from './api/MainAPI';

const ContactsPage = ({ setMobileCategory }) => {
    const location = useLocation();

    useEffect(() => {
        setMobileCategory(false);
    }, [location]);

    useEffect(() => {
        getAboutUsAPI.get().then((res) => {
            updateAboutUs(res?.data)
        })
    })

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div class="main">
                <MainContacts isContactPage />
            </div>
        </>
    );
};

export default ContactsPage;
