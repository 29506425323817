import { useEffect, useState } from 'react';
import useScrollLock from '../../help/useScrollLock';
import * as Styled from './Slider.styled';

const Slider = ({ urls, setShowModal, chooseIndex }) => {
    const [index, setIndex] = useState(chooseIndex);
    const [picList, setPicList] = useState(urls);

    useEffect(() => {
        setPicList(urls);
    }, [urls]);

    const onClickNext = () => {
        if (index + 1 === picList.length) {
            setIndex(0);
        } else {
            setIndex(index + 1);
        }
    };

    const onClickPrevious = () => {
        if (index - 1 === -1) {
            setIndex(picList.length - 1);
        } else {
            setIndex(index - 1);
        }
    };

    useScrollLock();

    return (
        <>
            <Styled.SliderWrapper>
                <div>
                    <Styled.SliderMainImg src={picList[index]} />
                    <Styled.PrevButton
                        onClick={onClickPrevious}
                        src="./img/left-arrow.svg"
                    />
                    <Styled.NextButton
                        onClick={onClickNext}
                        src="./img/left-arrow.svg"
                    />
                    <Styled.CloseButton
                        src="./img/close.png"
                        alt="close"
                        onClick={() => setShowModal(false)}
                    />
                </div>
            </Styled.SliderWrapper>
        </>
    );
};

export default Slider;
