import { useStore } from 'effector-react';
import { useState, useEffect } from 'react';
import { $mainState } from '../../../store/Store';
import BlogItem from './BlogItem';
import * as Styled from './Blog.styled';

const Blog = ({header, type}) => {
    const data = useStore($mainState);
    const [blogData, setBlogData] = useState(data[type]);

    useEffect(() => {
        setBlogData(data[type]);
    }, [data, type]);

    return (
        <>
            {!!blogData && (
                <Styled.MainWrapper>
                    <Styled.InnerWrapper>
                        <Styled.Title>{header}</Styled.Title>
                        <Styled.Container>
                        {(blogData || []).sort((a,b) => a?.order - b?.order).map((item) => (
                            <BlogItem
                                title={item?.title}
                                text={item?.text}
                                link={item?.link}
                                image={item?.image}
                            />
                        ))}
                    </Styled.Container>
                    </Styled.InnerWrapper>
                </Styled.MainWrapper>
            )}
        </>
    );
};

export default Blog;