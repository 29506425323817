import { Link } from 'react-router-dom';
import * as Styled from './Subcategory.styled';

const Subcategory = ({
    title,
    categorySlug,
    subcategorySlug,
    handleServiceScroll,
}) => {
    return (
        <Link
            to={`/${categorySlug}/${subcategorySlug}`}
            onClick={handleServiceScroll}
        >
            <Styled.SubcategoryWrapper>
                <h3>{title}</h3>
            </Styled.SubcategoryWrapper>
        </Link>
    );
};

export default Subcategory;
