import styled from 'styled-components';

export const MainWrapper = styled.div`
    display: flex;

    picture {
        min-height: 400px;
        min-width: 400px;
        max-height: 100%;
        max-width: 750px;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 5px 0 0 5px;

            @media only screen and (max-width: 768px) {
                border-radius: 5px 5px 0 0;
            }
        }

        @media only screen and (max-width: 1120px) {
            min-height: auto;
            min-width: auto;
            max-width: 500px;
        }

        @media only screen and (max-width: 768px) {
            max-height: 550px;
            max-width: 550px;
        }
    }

    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }
`;

export const ReviewContainer = styled.div`
    padding: 40px 20px 20px 20px;

    h5 {
        font-weight: 700;
        font-size: 20rem;
    }
`;

export const Line = styled.div`
    padding-top: 20px;

    span {
        font-weight: 700;
    }
`;

export const StatsContainer = styled.div`
    display: flex;
    padding: 25px 0;

    @media only screen and (max-width: 465px) {
        flex-direction: column;
    }
`;

export const Stat = styled.div`
    display: flex;
    padding-right: 10px;
    border: 2px solid #c5cad9;
    border-radius: 5px;
    padding: 5px;
    margin-right: ${(props) => props.marginRight}rem;

    div {
        h6 {
            font-size: 20rem;
            color: #5a06ff;

            @media only screen and (max-width: 460px) {
                font-size: 15rem;
            }
        }
    }

    @media only screen and (max-width: 465px) {
        margin-bottom: ${(props) => props.marginRight}rem;
        margin-right: 0;
    }
`;

export const SVGContainer = styled.div`
    margin-right: 10px;
    width: 45px;
    height: 45px;
    svg {
        width: 100%;
        height: 100%;
    }

    @media only screen and (max-width: 365px) {
        width: 30px;
        height: 30px;
    }
`;

export const Description = styled.div`
    padding: 10rem;
    background: white;
    border-radius: 5px;
    max-width: 60vw;

    @media only screen and (max-width: 768px) {
        max-width: 70vw;
    }
`;
