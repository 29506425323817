import FooterNav from './FooterNav';

const Footer = () => {
    return (
        <>
            <footer class="footer">
                <div class="footer__wrap wrap">
                    <FooterNav />
                    <div class="footer__content">
                        <p>
                            Сервисный центр Repair Service является
                            неавторизованным сервисным центром и не аффилирован
                            с компанией Apple Inc., Samsung, Asus, Xiaomi,
                            Lenovo, Huawei, Honor, Meizu, Sony, Realme, DEXP.
                            Apple, iPhone, iPod, iPad, Mac, iMac, iTunes,
                            MacBook, iOS, Mac OS, Apple Watch, Airpods, AirPods
                            Pro, iCloud, Apple ID, AirTag является
                            зарегистрированным товарными знаками компании Apple
                            Inc. Huawei, а так же Honor является
                            зарегистрированным товарными знаками компании HUAWEI
                            TECHNOLOGIES CO., LTD. Samsung является
                            зарегистрированным товарным знаком компании Samsung
                            Electronics Co. Ltd. Sony является
                            зарегистрированным оварным знаком компании Sony
                            Corporation. Asus является зарегистрированным
                            товарным знаком компании ASUSTeK Computer Inc.
                            Lenovo является зарегистрированным товарным знаком
                            компании Lenovo Group Limited. Xiaomi является
                            зарегистрированным товарным знаком компании Xiaomi
                            Corporation.
                        </p>
                        <p>
                            Обозначение используется не с целью индивидуализации
                            соответствующих услуг по ремонту и введения
                            посетителей в заблуждение, а с целью информирования
                            потребителей о предоставляемых услугах в отношении
                            техники правообладателей. Вся информация на сайте
                            носит исключительно информационный характер и не
                            является публичной офертой.
                        </p>
                    </div>
                    <div class="footer__bottom flex flex_vertical flex_justify">
                        <div class="footer__polity">
                            Copyright © Repair-service {new Date()?.getFullYear()} | Все права защищены
                        </div>
                        <a
                            class="footer__develop"
                            href="https://t.me/requesta_tech"
                            target="_blank"
                        >
                            Разработано - requesta.tech
                        </a>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
