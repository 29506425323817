import styled from 'styled-components';

export const MainWrapper = styled.div`
    padding: 113rem 0;
    background: #f8f9fc;
    overflow: hidden;

    @media only screen and (max-width: 580px) {
        padding: 60rem 0 79rem;
    }
`;

export const InnerWrapper = styled.div`
    padding: 0 100px;
    max-width: 1640rem;
    margin: auto;
    position: relative;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 1120px) {
        padding: 0 20px;
    }
`;

export const ReviewsContainer = styled.div`
    border-radius: 5px;
    width: 60vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    @media only screen and (max-width: 580px) {
        flex-direction: column;
        align-items: center;

        div:last-child{
            display: none;
        }
    }
`;

export const ImgContainer = styled.div`
    max-width: 48%;
    max-height: 400px;
    border-radius: 5px;

    img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        object-fit: cover;
    }

    @media only screen and (max-width: 580px) {
        max-width: 100%;
    }
`;

export const BrowseButton = styled.button`
    color: #0d1221;
    background: none;
    border: none;
    min-height: 70px;
    min-width: 70px;
    height: 70px;
    width: 70px;

    svg {
        height: 100%;
        width: 100%;
    }

    &:hover {
        cursor: pointer;
    }
`;

export const Title = styled.div`
    margin-bottom: 40px;
    text-align: center;
    font-weight: 700;
    font-size: 54rem;
    line-height: 100%;

    @media only screen and (max-width: 1440px) {
        font-size: 50rem;
    }
    @media only screen and (max-width: 1120px) {
        font-size: 42rem;
    }
    @media only screen and (max-width: 768px) {
        font-size: 38rem;
    }
    @media only screen and (max-width: 580px) {
        font-size: 24rem;
    }
`;
