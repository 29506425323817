const Address = ({ city }) => {
    return (
        <>
            <div class="contact__list flex">
                <div class="contact__item">
                    <div class="contact__item-icon">
                        <svg class="icon icon-location" viewBox="0 0 18 21">
                            <use xlinkHref="./icons/sprite.svg#location"></use>
                        </svg>
                    </div>
                    <div class="contact__item-wrap">
                        <div class="contact__item-title h4">
                            {`г. ${city?.name}, ${city?.address}`}
                        </div>
                    </div>
                </div>
                <div class="contact__item">
                    <div class="contact__item-icon">
                        <svg class="icon icon-clock" viewBox="0 0 19 19">
                            <use xlinkHref="./icons/sprite.svg#clock"></use>
                        </svg>
                    </div>
                    <div class="contact__item-wrap">
                        {city?.working_time?.map((item) => (
                            <div className="contact__item-title h4">
                                <span>
                                    {item.day} {item.time}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
                <div class="contact__item">
                    <div class="contact__item-icon">
                        <svg class="icon icon-phone" viewBox="0 0 18 19">
                            <use xlinkHref="./icons/sprite.svg#phone"></use>
                        </svg>
                    </div>
                    <div class="contact__item-wrap">
                        <div class="contact__item-title h4">
                            <a href="tel:+79027508787">{city?.phone}</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Address;
