const FooterContactItem = ({ phone, address, name }) => {
    return (
        <>
            <div class="footer__contact-item">
                <a class="footer__phone" href="tel:89027508787">
                    <span class="footer__phone-icon">
                        <svg class="icon icon-tel" viewBox="0 0 13 12">
                            <use xlinkHref="./icons/sprite.svg#tel"></use>
                        </svg>
                    </span>
                    <span class="footer__phone-text h5">{phone}</span>
                </a>
                <div class="footer__adress">
                    <div class="footer__adress-icon">
                        <svg class="icon icon-marker" viewBox="0 0 16 20">
                            <use xlinkHref="./icons/sprite.svg#marker"></use>
                        </svg>
                    </div>
                    <div class="footer__adress-text">
                        {`г, ${name} ул. ${address}`}
                    </div>
                </div>
            </div>
        </>
    );
};

export default FooterContactItem;
