import * as Styled from './ConsultButton.styled';

const ConsultButton = ({setShowModal}) => {
    return (
        <button
            class="main__btn btn btn_big btn_blue js-modal"
            onClick={() => setShowModal(true)}
        >
            <span class="btn__text">Узнать стоимость ремонта</span>
            <span class="btn__arrow">
                <span class="btn__arrow-inner">
                    <svg class="icon icon-arrow" viewBox="0 0 14 15">
                        <use xlinkHref="./icons/sprite.svg#arrow"></use>
                    </svg>
                </span>
            </span>
        </button>
    );
};

export default ConsultButton;
