import { useStore } from 'effector-react';
import { useEffect, useRef, useState } from 'react';
import { $mainState, updateServiceRef } from '../../../store/Store';
import ServiceItem from './SeviceItem';

const Services = ({ setShowModal }) => {
    const data = useStore($mainState);
    const [servicesData, setServicesData] = useState(data?.services);
    const [showMore, setShowMore] = useState(false);
    const serviceRef = useRef();

    useEffect(() => {
        setServicesData(data?.services);

        return () => {
            setShowMore(false);
        };
    }, [data]);

    useEffect(() => {
        updateServiceRef(serviceRef?.current);
    }, [serviceRef?.current]);

    const numberOfItems = showMore ? servicesData?.length : 4;

    return (
        <>
            {!!servicesData.length && (
                <div class="price js-tabs" ref={serviceRef}>
                    <div class="price__wrap wrap">
                        <div class="price__title h2">Цены на ремонт</div>
                        <div class="price__tabs js-tab">
                            <div class="price__tab js-tab">
                                <div class="price__price-table price-table js-price">
                                    <div class="price-table__row price-table__row_head">
                                        <div class="price-table__column">
                                            <div class="price-table__title h5">
                                                Услуга
                                            </div>
                                        </div>
                                        <div class="price-table__column">
                                            <div class="price-table__label">
                                                Время ремонта
                                            </div>
                                        </div>
                                        <div class="price-table__column">
                                            <div class="price-table__label">
                                                Гарантия
                                            </div>
                                        </div>
                                        <div class="price-table__column flex flex_vertical">
                                            <div class="price-table__label">
                                                Цена
                                            </div>
                                        </div>
                                    </div>
                                    {servicesData
                                        .slice(0, numberOfItems)
                                        .map((item) => (
                                            <ServiceItem
                                                key={item?.id}
                                                guarantee={item?.guarantee}
                                                price={item?.price}
                                                time={item?.time}
                                                title={item?.title}
                                                setShowModal={setShowModal}
                                            />
                                        ))}
                                </div>
                                {servicesData.length >= 5 && (
                                    <div class="price__bottom flex flex_center">
                                        {servicesData.length !==
                                        numberOfItems ? (
                                            <button
                                                class="price__btn btn btn_small btn_gray price__more js-more-price"
                                                onClick={() =>
                                                    setShowMore(true)
                                                }
                                            >
                                                <span class="btn__text">
                                                    Показать больше
                                                </span>
                                            </button>
                                        ) : (
                                            <button
                                                class="price__btn btn btn_small btn_gray price__more js-more-price"
                                                onClick={() =>
                                                    setShowMore(false)
                                                }
                                            >
                                                <span class="btn__text">
                                                    Свернуть
                                                </span>
                                            </button>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Services;
