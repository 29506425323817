const InfoButtons = ({ setShowModal }) => {
    return (
        <>
            <div class="main__buttons">
                <a
                    class="main__btn btn btn_big btn_green"
                    href="https://wa.me/+79027508787"
                >
                    <span class="btn__icon btn__icon_whatsapp">
                        <svg class="icon icon-whatsapp" viewBox="0 0 26 26">
                            <use xlinkHref="./icons/sprite.svg#whatsapp"></use>
                        </svg>
                    </span>
                    <span class="btn__text">Написать в WhatsApp</span>
                </a>
                <a
                    class="main__btn btn btn_call btn_blue"
                    href="tel:+79027508787"
                >
                    <span class="btn__wrap">
                        <span class="btn__icon btn__icon_phone">
                            <svg class="icon icon-phone" viewBox="0 0 18 19">
                                <use xlinkHref="./icons/sprite.svg#phone"></use>
                            </svg>
                        </span>
                        <span class="btn__text">Позвонить</span>
                    </span>{' '}
                </a>
                <div class="main__btn btn btn_call btn_dark js-modal">
                    <span class="btn__wrap">
                        <span class="btn__icon btn__icon_paper">
                            <svg class="icon icon-paper" viewBox="0 0 17 19">
                                <use xlinkHref="./icons/sprite.svg#paper"></use>
                            </svg>
                        </span>
                        <span
                            onClick={() => setShowModal(true)}
                            class="btn__text"
                        >
                            Оставить заявку
                        </span>
                    </span>
                </div>
            </div>
        </>
    );
};

export default InfoButtons;
