import React from 'react';

import * as S from './Info.styled';

export const Info = () => {
    return (
        <S.Container>
            <S.Item>
                <S.Label>
                    <p>ФИО</p>
                </S.Label>
                <S.Body>
                    <p>Пешехонов Максим Валериевич</p>
                </S.Body>
            </S.Item>
            <S.Item>
                <S.Label>
                    <p>ИНН</p>
                </S.Label>
                <S.Body>
                    <p>710608576925</p>
                </S.Body>
            </S.Item>
            <S.Item>
                <S.Label>
                    <p>ОГРН</p>
                </S.Label>
                <S.Body>
                    <p>321710000062393</p>
                </S.Body>
            </S.Item>
        </S.Container>
    );
};
