import { useEffect } from 'react';

const useScrollLock = () => {
    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
            if (document.querySelectorAll('[data-modal]').length <= 1) {
                document.body.style.overflowY = 'auto';
            }
        };
    }, []);
};

export default useScrollLock;
