import * as Styled from './RepairTermsAndCost.styled';

const RepairTermsAndCost = () => {

    return (
        <Styled.MainWrapper>
            <Styled.InnerWrapper>
                <Styled.Title>
                    Стоимость ремонта и оплата
                </Styled.Title>
                <Styled.FrameBlock>
                    <Styled.TopLeft />
                    <Styled.TopRight />
                    <Styled.BottomLeft />
                    <Styled.BottomRight />
                    <Styled.FrameText>
                        <Styled.BoldText>
                            Ремонт только после согласования стоимости
                        </Styled.BoldText>
                    </Styled.FrameText>
                </Styled.FrameBlock>
                <Styled.TextContainer>
                    Стоимость объявляется только после диагностики. Мы обязательно согласовываем её с Вами, а затем приступаем к ремонту.
                    Итоговая цена зависит от объёма работ, сложности поломки и количества необходимых деталей.
                </Styled.TextContainer>
                <Styled.BoldText>
                    Оплачиваете Вы только по результату проведённых работ!
                </Styled.BoldText>
            </Styled.InnerWrapper>
        </Styled.MainWrapper>
    );
};

export default RepairTermsAndCost;
