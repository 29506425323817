import { useStore } from 'effector-react';
import { $mainState } from '../../../store/Store';
import { useEffect } from 'react';
import { useState } from 'react';
import InfoGarranty from './InfoGarranty';
import InfoButtons from './InfoButtons';
import { useParams } from 'react-router-dom';

import '../css/style.css';

const Info = ({ isCategory, setShowModal }) => {
    const data = useStore($mainState);
    const { subcategoryTitle } = useParams();
    const [categoryData, setCategoryData] = useState(data?.categoryDetail);

    useEffect(() => {
        isCategory
            ? setCategoryData(data?.categoryDetail)
            : //ЭТО ХУЕТА, ПОТОМ ПЕРЕДЕЛАТЬ!!!
              setCategoryData({
                  description:
                      'Качественный ремонт с документальной гарантией до 1 года <p class="another-color">Отправь заявку и получи скидку 30%</p>',
                  id: 1,
                  image: 'https://770516.selcdn.ru/repair-service/media/main/main_icon.webp',
                  title: 'СЕРВИСНЫЙ ЦЕНТР ПО РЕМОНТУ ЭЛЕКТРОННИКИ В ТУЛЕ',
              });
    }, [data?.categoryDetail, isCategory]);

    return (
        <>
            <div class="main__wrap wrap" style={{ marginTop: '150px' }}>
                <div class="main__inner flex flex_justify">
                    <div class="main__box">
                        <div class="main__title h1">
                            {subcategoryTitle &&
                            categoryData?.subcategory?.filter(
                                (item) => item.slug === subcategoryTitle
                            ).length > 0
                                ? categoryData?.subcategory?.filter(
                                      (item) => item.slug === subcategoryTitle
                                  )[0].title
                                : categoryData?.title}
                        </div>
                        <div class="main__center">
                            <button
                                class="main__btn btn btn_big btn_blue js-modal"
                                onClick={() => setShowModal(true)}
                            >
                                <span class="btn__text">
                                    Узнать стоимость ремонта
                                </span>
                                <span class="btn__arrow">
                                    <span class="btn__arrow-inner">
                                        <svg
                                            class="icon icon-arrow"
                                            viewBox="0 0 14 15"
                                        >
                                            <use xlinkHref="./icons/sprite.svg#arrow"></use>
                                        </svg>
                                    </span>
                                </span>
                            </button>
                            <div class="main__desc color-gray">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: categoryData?.description,
                                    }}
                                />
                                {categoryData?.sale_text && (
                                    <p className="another-color">
                                        {categoryData?.sale_text}
                                    </p>
                                )}
                            </div>
                        </div>
                        <InfoButtons setShowModal={setShowModal} />
                        <div class="main__list flex flex_justify">
                            <InfoGarranty />
                        </div>
                    </div>
                    <div class="main__image">
                        <picture>
                            <img
                                src={
                                    subcategoryTitle &&
                                    data?.categoryDetailSubcategories?.filter(
                                        (item) => item.slug === subcategoryTitle
                                    ).length > 0
                                        ? data?.categoryDetailSubcategories?.filter(
                                              (item) =>
                                                  item.slug === subcategoryTitle
                                          )[0].image ?? categoryData?.image
                                        : categoryData?.image
                                }
                                alt="mainImg"
                            />
                        </picture>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Info;
