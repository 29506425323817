import ChooseCity from './ChooseCity';
import { useStore } from 'effector-react';
import { $mainState } from '../../../store/Store';
import { useEffect } from 'react';
import { useState, useRef } from 'react';
import Gallery from './Gallery';
import Address from './Address';
import YaMap from './YaMap';
import Blog from '../blog/Blog';
import AboutUs from '../aboutUs/AboutUs';

import * as Styled from './MainContacts.styled';
import { Info } from './Info';

const MainContacts = ({ isContactPage }) => {
    const data = useStore($mainState);
    const [cities, setCities] = useState(data?.cities);
    const [actualCity, setActualCity] = useState(cities[0]);

    useEffect(() => {
        setCities(data?.cities);
        if (!actualCity?.id) {
            setActualCity({
                ...data?.cities?.[0],
                choose: true,
            });
        }
    }, [data]);

    return (
        <>
            <div
                class="contact js-tabs"
                style={isContactPage ? { marginTop: '30px' } : {}}
            >
                <div class="contact__wrap wrap">
                    <ChooseCity
                        cities={cities}
                        actualCity={actualCity}
                        setActualCity={setActualCity}
                    />
                    <div class="contact__tabs">
                        <div class="contact__tab js-tab">
                            <Address city={actualCity} />
                            <a
                                class="contact__btn btn btn_green contact__btn contact__btn_mobile"
                                href="https://wa.me/+79027508787"
                            >
                                <span class="btn__text">
                                    Написать в WhatsApp
                                </span>
                            </a>
                            <Styled.GalleryAndInfo>
                                <Gallery actualCity={actualCity} />
                                <Info />
                            </Styled.GalleryAndInfo>
                        </div>
                    </div>
                </div>
            </div>
            {data?.advantages?.length && (
                <Blog
                    type="advantages"
                    header="Почему стоит выбрать именно нас"
                />
            )}
            <AboutUs />
            <YaMap actualCity={actualCity} />
        </>
    );
};

export default MainContacts;
