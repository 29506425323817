import * as Styled from './Review.styled';

const Review = ({
    title,
    problem,
    solution,
    image,
    amount,
    timeSpend,
    description,
}) => {
    let hourSpelling = "часов";
    if (/\d*1$/.test(timeSpend)) { hourSpelling = "час"; }
    if (/\d*2$|3$|4$/.test(timeSpend)) { hourSpelling = "часа"; }
    if (/\d*11$|12$|13$|14$/.test(timeSpend)) { hourSpelling = "часов"; }
    if (/\d*\.\d*/.test(timeSpend)) { hourSpelling = "часа"; }
    if (/[^0-9.]/.test(timeSpend)) { hourSpelling = ""; }

    return (
        <Styled.MainWrapper>
            <picture>
                <img src={image} alt="" />
            </picture>
            <Styled.ReviewContainer>
                <h5>{title}</h5>
                <Styled.Line>
                    <span>Проблема: </span>
                    {problem}
                </Styled.Line>
                <Styled.Line>
                    <span>Решение: </span>
                    {solution}
                </Styled.Line>
                <Styled.StatsContainer>
                    <Styled.Stat marginRight="10">
                        <Styled.SVGContainer>
                            <svg>
                                <use xlinkHref="./icons/sprite.svg#wallet"></use>
                            </svg>
                        </Styled.SVGContainer>
                        <div>
                            <div>Стоимость работы</div>
                            <h6>{amount} руб.</h6>
                        </div>
                    </Styled.Stat>
                    <Styled.Stat marginRight="0">
                        <Styled.SVGContainer>
                            <svg>
                                <use xlinkHref="./icons/sprite.svg#stopwatch"></use>
                            </svg>
                        </Styled.SVGContainer>
                        <div>
                            <div>Время работы</div>
                            <h6>
                                {timeSpend} {hourSpelling}
                            </h6>
                        </div>
                    </Styled.Stat>
                </Styled.StatsContainer>
                <Styled.Description>{description}</Styled.Description>
            </Styled.ReviewContainer>
        </Styled.MainWrapper>
    );
};

export default Review;
