import { useState } from 'react';
import { sendFeedbackAPI } from '../main/api/MainAPI';
import * as Styled from './MainModals.styled';
import useScrollLock from './useScrollLock';

const MainModal = ({ setShowModal, setSuccessModal }) => {
    const [name, setName] = useState('');
    const [number, setNumber] = useState(null);
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false)

    const onClickHandler = () => {
        setIsLoading(true)
        try {
            sendFeedbackAPI
                .post('', {
                    name,
                    phone: number,
                    message,
                })
                .then((res) => {
                    setShowModal(false);
                    setSuccessModal(true);
                })
                .catch((err) => {
                    setShowModal(false);
                }).finally(() => setIsLoading(false));
        } catch {
            console.log('ERR');
        }
    };


    useScrollLock();

    return (
        <>
            <Styled.ModalWrapper>
                <div
                    className="modals modals_recall"
                    style={{ top: 'calc(50% - 384.5px)' }}
                >
                    <Styled.MainClose
                        src="./img/close.png"
                        alt="close"
                        onClick={() => setShowModal(false)}
                    />
                    <div className="modals__recall recall">
                        <div className="recall__preview">
                            <picture>
                                <source
                                    type="image/webp"
                                    srcset="./img/recall__preview.webp"
                                />
                                <img
                                    src="./img/recall__preview.jpg"
                                    className="object-fit"
                                    alt="main"
                                />
                            </picture>
                        </div>
                        <div className="recall__form">
                            <div className="recall__mobile">
                                {/* <div className="recall__mobile-title">
                                    Как вам удобнее <br />с нами связаться?
                                </div> */}
                                {/* <div className="recall__mobile-list flex flex_justify"> */}
                                {/* <label class="recall__btn btn btn_call btn_blue">
                                        <input
                                            className="btn__input"
                                            type="radio"
                                            name="radio"
                                        />
                                        <span className="btn__wrap">
                                            <span className="btn__icon btn__icon_phone">
                                                <svg
                                                    class="icon icon-phone"
                                                    viewBox="0 0 18 19"
                                                >
                                                    <use xlinkHref="./icons/sprite.svg#phone"></use>
                                                </svg>
                                            </span>
                                            <span className="btn__text">
                                                Позвонить
                                            </span>
                                        </span>
                                    </label>
                                    <label className="recall__btn btn btn_call btn_green">
                                        <span className="btn__wrap">
                                            <span className="btn__icon btn__icon_whatsapp">
                                                <svg
                                                    className="icon icon-whatsapp"
                                                    viewBox="0 0 26 26"
                                                >
                                                    <use xlinkHref="./icons/sprite.svg#whatsapp"></use>
                                                </svg>
                                            </span>
                                            <span className="btn__text">
                                                Написать в WhatsApp
                                            </span>
                                        </span>
                                    </label> */}
                                {/* </div> */}
                            </div>
                            <div className="recall__title">
                                Оставьте заявку и мы перезвоним
                            </div>
                            <div className="recall__desc color-gray h5">
                                Напишите свой телефон и менеджер перезвонит вам
                            </div>
                            <div className="recall__row">
                                <div className="recall__column">
                                    <div className="recall__field field">
                                        <div className="field__icon">
                                            <svg
                                                className="icon icon-user"
                                                viewBox="0 0 17 19"
                                            >
                                                <use xlinkHref=".icons/sprite.svg#user"></use>
                                            </svg>
                                        </div>
                                        <input
                                            style={{
                                                width: '58.5em',
                                            }}
                                            className="field__input text-field-modal"
                                            name="name"
                                            placeholder="Текст сообщения"
                                            type="text"
                                            value={message}
                                            onChange={(e) =>
                                                setMessage(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="recall__row">
                                <div className="recall__column">
                                    <div className="recall__field field">
                                        <div className="field__icon">
                                            <svg
                                                className="icon icon-user"
                                                viewBox="0 0 17 19"
                                            >
                                                <use xlinkHref=".icons/sprite.svg#user"></use>
                                            </svg>
                                        </div>
                                        <input
                                            className="field__input"
                                            name="name"
                                            placeholder="Ваше имя"
                                            type="text"
                                            value={name}
                                            onChange={(e) =>
                                                setName(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="recall__column">
                                    <div className="recall__field field">
                                        <div className="field__icon">
                                            <svg
                                                className="icon icon-phone"
                                                viewBox="0 0 18 19"
                                            >
                                                <use xlinkHref="./icons/sprite.svg#phone"></use>
                                            </svg>
                                        </div>
                                        <input
                                            className="field__input"
                                            name="phone"
                                            placeholder="Ваш телефон"
                                            type="tel"
                                            value={number}
                                            onChange={(e) =>
                                                setNumber(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="recall__column">
                                    <button
                                        className={`recall__btn btn btn_big ${isLoading ? 'btn_gray_new': 'btn_blue'}`}
                                        disabled={isLoading}
                                        onClick={onClickHandler}
                                    >
                                        <span className="btn__text">
                                            {isLoading ? 'Отправка заявки' : 'Перезвоните мне'}
                                        </span>
                                        <span className="btn__arrow">
                                            <span className="btn__arrow-inner">
                                                {
                                                    !isLoading &&
                                                    <svg
                                                        className="icon icon-arrow"
                                                        viewBox="0 0 14 15"
                                                    >
                                                        <use xlinkHref="./icons/sprite.svg#arrow"></use>
                                                    </svg>
                                                }
                                            </span>
                                        </span>
                                    </button>
                                    <div className="recall__polity">
                                        <label className="recall__checkbox checkbox">
                                            <input
                                                className="checkbox__input"
                                                type="checkbox"
                                                name="polity"
                                                checked="checked"
                                            />
                                            {/* <span className="checkbox__icon">
                                                <svg
                                                    className="icon icon-check"
                                                    viewBox="0 0 13 10"
                                                >
                                                    <use xlinkHref="./icons/sprite.svg#check"></use>
                                                </svg>
                                            </span> */}
                                            {/* <span className="checkbox__text">
                                                Даю согласие на обработку <br />
                                                моих персональных данных
                                            </span> */}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Styled.ModalWrapper>
        </>
    );
};

export default MainModal;
