import styled from 'styled-components';

export const Container = styled.div`
    width: 500px;
    max-width: 500px;
    position: relative;
    top: 45px;
    margin-left: 50px;

    @media only screen and (max-width: 868px) {
        margin-left: 0;
        top: 25px;
    }

    @media only screen and (max-width: 696px) {
        width: 100%;
        margin-right: 0;
        max-width: none;
    }
`;

export const Item = styled.div`
    margin-bottom: 38px;

    @media only screen and (max-width: 580px) {
        margin-bottom: 20px;
    }
`;

export const Label = styled.div`
    p {
        font-weight: 700;
        margin-bottom: 10px;
    }
`;

export const Body = styled.div`
    border: 2px solid lightgray;
    border-radius: 5px;
    padding: 5px;

    @media only screen and (max-width: 696px) {
        width: 100%;
    }
`;
