import * as Styled from './EmployeesItem.styled';

const EmployeesItem = ({ name, surname, description, image, workExperience }) => {
    return (
        <Styled.EmployeeWrapper>
            <Styled.Preview>
                <picture>
                    <img src={image} alt=""/>{' '}
                </picture>
            </Styled.Preview>
            <Styled.EmployeeInfo>
                <Styled.Name>
                    {name} {surname}
                </Styled.Name>
                <Styled.Description>{description}</Styled.Description>
                {workExperience && <Styled.Description><b>{workExperience}</b></Styled.Description>}
            </Styled.EmployeeInfo>
        </Styled.EmployeeWrapper>
    );
};

export default EmployeesItem;
