import { useStore } from 'effector-react';
import { useState, useEffect, useCallback } from 'react';
import { $mainState } from '../../../store/Store';
import * as Styled from './WorkflowBlock.styled';

const WorkflowBlock = () => {
    const data = useStore($mainState);
    const [workflowData, setWorkflowData] = useState(
        data?.workflow
    );

    const [workflowToShow, setWorkflowToShow] = useState([0,1]);

    useEffect(() => {
        setWorkflowData(data?.workflow);
    }, [data?.workflow]);

    const nextReviewClickHandler = useCallback(() => {
        setWorkflowToShow((prevState) => prevState.map((item) => item === +workflowData?.length - 1 ? 0 : +item + 1))
    },[workflowData?.length]);

    const prevReviewClickHandler = useCallback(() => {
        setWorkflowToShow((prevState) => prevState.map((item) => !item ? +workflowData?.length - 1 : +item - 1))
    },[workflowData?.length]);

    const dataIsNotEmpty = !!workflowData?.length;

    return (
        dataIsNotEmpty &&
            <Styled.MainWrapper>
                {<Styled.Title>Рабочий процесс в нашем сервисном центре</Styled.Title>}
                {<Styled.InnerWrapper>
                    <Styled.BrowseButton gridArea = "a" justifySelf = "left"
                        type="button"
                        onClick={prevReviewClickHandler}
                    >
                        <svg>
                            <use xlinkHref="./icons/sprite.svg#chevron_left"></use>
                        </svg>
                    </Styled.BrowseButton>
                    <Styled.ReviewsContainer  gridArea = "c">
                        {(workflowData || [])[workflowToShow[0]] &&
                            <Styled.ImgContainer>
                                <img src={(workflowData || [])[workflowToShow[0]]?.photo} alt='workflowImg'/>
                            </Styled.ImgContainer>}
                        {(workflowData || [])[workflowToShow[1]] &&
                            <Styled.ImgContainer>
                                <img src={(workflowData || [])[workflowToShow[1]]?.photo} alt='workflowImg'/>
                            </Styled.ImgContainer>}
                    </Styled.ReviewsContainer>
                    <Styled.BrowseButton  gridArea = "b" justifySelf = "right"
                        type="button"
                        onClick={nextReviewClickHandler}
                    >
                        <svg>
                            <use xlinkHref="./icons/sprite.svg#chevron_right"></use>
                        </svg>
                    </Styled.BrowseButton>
                </Styled.InnerWrapper>}
            </Styled.MainWrapper>
    );
};

export default WorkflowBlock;
