import ConsultButton from './ConsultButton';
import * as Styled from './Consult.styled';

const Consult = ({setShowModal}) => {

    return (
        <Styled.MainWrapper>
            <Styled.InnerWrapper>
                <Styled.Title>
                    Узнайте стоимость ремонта и получите
                    <span> скидку 500 руб. </span>
                    при первом обращении
                </Styled.Title>
                <ConsultButton setShowModal={setShowModal}/>
            </Styled.InnerWrapper>
        </Styled.MainWrapper>
    );
};

export default Consult;
