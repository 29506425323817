import MainContacts from './contacts/MainContacts';
import RepairBlock from './repair/RepairBlock';
import Info from './info/Info';
import MainModal from '../help/MainModal';
import { useEffect, useState } from 'react';
import SuccessModal from '../help/SuccessModal';
import { useLocation } from 'react-router-dom';
import { updateAboutUs, updateAdvantages } from '../../store/Store';
import { getAboutUsAPI, getAdvantagesAPI } from './api/MainAPI';

const MainPage = ({ setMobileCategory, mapRef }) => {
    const [showModal, setShowModal] = useState(false);
    const [showSuccessModal, setSuccessModal] = useState(false);
    const location = useLocation();

    useEffect(() => {
        setMobileCategory(false);
    }, [location]);

    useEffect(() => {
        getAboutUsAPI.get().then((res) => {
            updateAboutUs(res?.data)
        })

        getAdvantagesAPI.get().then((res) => {
            updateAdvantages(res?.data);
        });
    })

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div class="main">
                <Info setShowModal={setShowModal} />
                <RepairBlock />
                <MainContacts />
            </div>
            {showModal && (
                <MainModal
                    setShowModal={setShowModal}
                    setSuccessModal={setSuccessModal}
                />
            )}
            {showSuccessModal && (
                <SuccessModal setSuccessModal={setSuccessModal} />
            )}
        </>
    );
};

export default MainPage;
