import '../../../css/app.css';
import Contacts from './Contacts';
import { useStore } from 'effector-react';
import { $mainState } from '../../../store/Store';
import { useEffect } from 'react';
import { useState } from 'react';
import Phones from './Phones';
import { Link } from 'react-router-dom';

const Header = () => {
    const data = useStore($mainState);
    const [cities, setSities] = useState(data?.cities);

    useEffect(() => {
        setSities(data?.cities);
    }, [data]);

    return (
        <>
            <header class="header js-header">
                <div class="header__top">
                    <div class="header__wrap wrap">
                        <div class="header__row flex flex_vertical">
                            <div class="header__column header__column_left">
                                <Link
                                    class="header__logo"
                                    to="/"
                                    onClick={() => window.scrollTo(0, 0)}
                                >
                                    <span class="header__logo-img">
                                        <img
                                            src="./img/logo.png"
                                            alt="mainLogo"
                                        />
                                    </span>
                                    <span class="header__logo-text header__logo-text_desktop h4">
                                        Repair-service
                                    </span>
                                    <span class="header__logo-text header__logo-text_mobile h4">
                                        RS
                                    </span>
                                </Link>
                            </div>
                            <div class="header__column header__column_center flex">
                                {(cities || []).map((city) => (
                                    <Contacts
                                        key={city?.id}
                                        address={city?.address}
                                        workingDays={city?.working_days}
                                        workingHours={city?.working_hours}
                                        workingTimes={city?.working_time}
                                        name={city?.name}
                                        phone={city?.phone}
                                    />
                                ))}
                            </div>
                            <div class="header__column header__column_right flex flex_right flex_vertical">
                                <div class="header__social social">
                                    <a
                                        class="social__link social__link_whatsapp"
                                        href="https://wa.me/+79027508787"
                                    >
                                        <svg
                                            class="icon icon-whatsapp"
                                            viewBox="0 0 26 26"
                                        >
                                            <use xlinkHref="./icons/sprite.svg#whatsapp"></use>
                                        </svg>
                                    </a>
                                    <a
                                        class="social__link social__link_vk"
                                        href="https://vk.com/repair.srevice71"
                                    >
                                        <svg
                                            class="icon icon-vk"
                                            viewBox="0 0 25 14"
                                        >
                                            <use xlinkHref="./icons/sprite.svg#vk"></use>
                                        </svg>
                                    </a>
                                    <a
                                        class="social__link"
                                        href="https://t.me/PerfectRepair"
                                    >
                                        <img
                                            class="icon icon-instagram"
                                            src="./img/telega.png"
                                            alt="telega"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Header;
