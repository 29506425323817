const PromotionItem = ({ description, image, title }) => {
    return (
        <>
            <div class="repairs__item">
                <span class="repairs__preview">
                    <picture>
                        <img src={image} class="object-fit" />{' '}
                    </picture>
                </span>
                <span class="repairs__info">
                    <span class="repairs__item-title h3">{title}</span>
                    <span class="repairs__text">{description}</span>
                </span>
            </div>
        </>
    );
};

export default PromotionItem;
