import styled from 'styled-components';

export const MainWrapper = styled.div`
    padding: 113rem 0;
    background: #f8f9fc;
    overflow: hidden;

    @media only screen and (max-width: 580px) {
        padding: 60rem 0 79rem;
    }
`;

export const InnerWrapper = styled.div`
    padding: 0 100px;
    max-width: 1640rem;
    margin: auto;
    position: relative;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 1120px) {
        padding: 0 20px;
    }

    @media only screen and (max-width: 580px) {
        display: grid;
        grid-template-areas:
            'a b'
            'c c';
    }
`;

export const ReviewsContainer = styled.div`
    background: #f0f4f9;
    border-radius: 5px;
    width: 70vw;

    @media only screen and (max-width: 580px) {
        grid-area: ${(props) => props.gridArea};
    }
`;

export const BrowseButton = styled.button`
    color: #0d1221;
    background: none;
    border: none;
    height: 70px;
    width: 70px;

    svg {
        height: 100%;
        width: 100%;
    }

    &:hover {
        cursor: pointer;
    }

    @media only screen and (max-width: 580px) {
        grid-area: ${(props) => props.gridArea};
        justify-self: ${(props) => props.justifySelf};
    }
`;

export const Title = styled.div`
    margin-bottom: 40px;
    text-align: center;
    font-weight: 700;
    font-size: 54rem;
    line-height: 100%;

    @media only screen and (max-width: 580px) {
        font-size: 30rem;
    }

    @media only screen and (max-width: 1440px) {
        font-size: 50rem;
    }
    @media only screen and (max-width: 1120px) {
        font-size: 42rem;
    }
    @media only screen and (max-width: 768px) {
        font-size: 38rem;
    }
    @media only screen and (max-width: 580px) {
        font-size: 24rem;
    }
`;
