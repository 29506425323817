const GalleryItem = ({
    desription,
    source,
    type,
    videoModalHandler,
    sourceService,
    imageModalHandler,
    index,
}) => {
    return (
        <>
            <div
                class="contact__gallery-column"
                onClick={() =>
                    type === 'VIDEO'
                        ? videoModalHandler(sourceService)
                        : imageModalHandler(index)
                }
            >
                <div class="contact__gallery-item js-modal">
                    <span class="contact__gallery-preview">
                        <picture>
                            <img src={source} class="object-fit" />{' '}
                        </picture>
                    </span>
                    <span class="contact__gallery-bottom">
                        <span class="contact__gallery-icon">
                            <svg class="icon icon-photo" viewBox="0 0 17 14">
                                {type === 'VIDEO' ? (
                                    <use xlinkHref="./icons/sprite.svg#play"></use>
                                ) : (
                                    <use xlinkHref="./icons/sprite.svg#photo"></use>
                                )}
                            </svg>
                        </span>
                        <span class="contact__gallery-text">{desription}</span>
                    </span>
                </div>
            </div>
        </>
    );
};

export default GalleryItem;
