import * as Styled from './Slider.styled';

const VideoModal = ({ videoUrl, setShowVideoModal }) => {
    console.log('TTTTTT', videoUrl);
    return (
        <>
            <Styled.SliderWrapper>
                <div>
                    <iframe
                        width="100%"
                        height="100%"
                        src={videoUrl}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                        style={{ borderRadius: '0px' }}
                    ></iframe>
                    <Styled.CloseButton
                        src="./img/close.png"
                        alt="close"
                        onClick={() => setShowVideoModal(false)}
                    />
                </div>
            </Styled.SliderWrapper>
        </>
    );
};

export default VideoModal;
