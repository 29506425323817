import styled from 'styled-components';

export const MainWrapper = styled.div`
    padding: 20px 100px;
    background: #f8f9fc;
    overflow: hidden;

    max-width: 1640rem;
    margin: auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media only screen and (max-width: 1120px) {
        padding: 20px 20px;
    }

    @media only screen and (max-width: 580px) {
        padding: 20px 20px 79rem;
    }

    @media only screen and (max-width: 470px) {
        flex-direction: column;
    }
`;