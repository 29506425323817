import { useStore } from 'effector-react';
import { $mainState } from '../../../store/Store';
import { useEffect } from 'react';
import { useState } from 'react';
import FooterContactItem from './ FooterContactItem';
import { Link } from 'react-router-dom';

const FooterNav = () => {
    const data = useStore($mainState);
    const [cities, setSities] = useState(data?.cities);
    const [category, setCategory] = useState(data?.category);

    useEffect(() => {
        setSities(data?.cities);
        setCategory(data?.category);
    }, [data]);

    return (
        <>
            <div class="footer__mobile">
                <div class="footer__mobile-top flex flex_center">
                    <Link class="footer__logo" to="/">
                        <span class="footer__logo-img">
                            <img src="./img/logo.png" />
                        </span>
                        <span class="footer__logo-text h4">Repair-service</span>
                    </Link>
                </div>
                <div class="footer__mobile-center flex flex_vertical flex_justify">
                    <div class="footer__contact">
                        {(cities || []).map((item) => (
                            <div class="footer__contact-item">
                                <a class="footer__phone" href="tel:89027508787">
                                    <span class="footer__phone-icon">
                                        <svg
                                            class="icon icon-phone"
                                            viewBox="0 0 18 19"
                                        >
                                            <use xlinkHref="./icons/sprite.svg#phone"></use>
                                        </svg>
                                    </span>
                                    <span class="footer__phone-text">
                                        {item?.phone}
                                    </span>
                                </a>
                            </div>
                        ))}
                    </div>
                    <div class="footer__social social">
                        <a
                            class="social__link social__link_whatsapp"
                            href="https://wa.me/+79027508787"
                        >
                            <svg class="icon icon-whatsapp" viewBox="0 0 26 26">
                                <use xlinkHref="./icons/sprite.svg#whatsapp"></use>
                            </svg>
                        </a>
                        <a
                            class="social__link social__link_vk"
                            href="https://vk.com/repair.srevice71"
                        >
                            <svg class="icon icon-vk" viewBox="0 0 25 14">
                                <use xlinkHref="./icons/sprite.svg#vk"></use>
                            </svg>
                        </a>
                        <a
                            class="social__link"
                            href="https://t.me/repairservice71"
                        >
                            <img
                                class="icon icon-instagram"
                                src="./img/telega.png"
                                alt="telega"
                            />
                        </a>
                    </div>
                </div>
                <div class="footer__mobile-bottom">
                    <div class="footer__payment payment">
                        <a class="payment__item">
                            <img src="./img/visa.svg" />
                        </a>
                        <a class="payment__item">
                            <img src="./img/mastercard.svg" />
                        </a>
                        <a class="payment__item">
                            <img src="./img/mir.svg" />
                        </a>
                    </div>
                    <div class="footer__polity">
                        Copyright © Repair-service {new Date()?.getFullYear()} | <br />
                        Все права защищены
                    </div>
                    <a
                        className="footer__develop"
                        href="https://t.me/requesta_tech"
                        target="_blank"
                    >
                        Разработано - requesta.tech
                    </a>
                </div>
            </div>

            <div class="footer__top flex flex_justify">
                <div class="footer__column">
                    <a class="footer__logo" href="#">
                        {/*<span class="footer__logo-img">*/}
                        {/*    <img src="./img/logo_white.svg" />*/}
                        {/*</span>*/}
                        <span class="footer__logo-text h4">Repair-service</span>
                    </a>
                </div>
                <div class="footer__column">
                    <ul class="footer__nav flex">
                        <li class="footer__nav-item">
                            <Link class="footer__nav-link is-active" to="/">
                                Главная
                            </Link>
                        </li>
                        {(category || []).map((item) => (
                            <li key={item?.id} class="footer__nav-item">
                                <Link
                                    class="footer__nav-link"
                                    to={`/${item?.slug}`}
                                >
                                    {item?.title}
                                </Link>
                            </li>
                        ))}
                        <li class="footer__nav-item">
                            <Link class="footer__nav-link" to={'/contacts'}>
                                Контакты
                            </Link>
                        </li>
                    </ul>
                </div>
                <div class="footer__column">
                    <div class="footer__contact">
                        {(cities || []).map((item) => (
                            <FooterContactItem
                                key={item?.id}
                                phone={item?.phone}
                                address={item?.address}
                                name={item?.name}
                            />
                        ))}
                    </div>
                </div>
                <div class="footer__column">
                    <div class="footer__social social">
                        <a
                            class="social__link social__link_whatsapp"
                            href="https://wa.me/+79027508787"
                        >
                            <svg class="icon icon-whatsapp" viewBox="0 0 26 26">
                                <use xlinkHref="./icons/sprite.svg#whatsapp"></use>
                            </svg>
                        </a>
                        <a
                            class="social__link social__link_vk"
                            href="https://vk.com/repair.srevice71"
                        >
                            <svg class="icon icon-vk" viewBox="0 0 25 14">
                                <use xlinkHref="./icons/sprite.svg#vk"></use>
                            </svg>
                        </a>
                        <a
                            class="social__link"
                            href="https://t.me/repairservice71"
                        >
                            <img
                                class="icon icon-instagram"
                                src="./img/telega.png"
                                alt="telega"
                            />
                        </a>
                    </div>
                    <div class="footer__payment payment">
                        <a class="payment__item">
                            <img src="./img/visa.svg" />
                        </a>
                        <a class="payment__item">
                            <img src="./img/mastercard.svg" />
                        </a>
                        <a class="payment__item">
                            <img src="./img/mir.svg" />
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FooterNav;
